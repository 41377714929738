import { InputHTMLAttributes } from 'react';
import 'react-phone-number-input/style.css';
import localeRu from 'react-phone-number-input/locale/ru.json';
import localeEn from 'react-phone-number-input/locale/en.json';
import clsx from 'clsx';
import PhoneInput, { Value } from 'react-phone-number-input';
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js';

import useTranslation from 'next-translate/useTranslation';
import styles from './input-phone.module.scss';


export type InputPhoneProps = {
  fullWidth?: boolean;
  international?: boolean;
  error?: boolean;
  value?: string | Value;
  onChange?: (value: Value | undefined) => void;
  defaultCountry?: CountryCode
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'>;

const noop = () => {};

export const InputPhone = ({
  fullWidth, error, value, onChange = noop, defaultCountry = 'TH', ...props
}: InputPhoneProps) => {
  const { lang } = useTranslation();

  const handleChange = (newValue: Value | undefined) => {
    const countryCode = `+${getCountryCallingCode(defaultCountry)}`;

    if (newValue === undefined || newValue === countryCode) {
      return;
    }

    onChange(newValue);
  };

  return (
    <PhoneInput
      {...props}
      value={value}
      onChange={handleChange}
      className={clsx(styles.wrapper, {
        [styles.wrapper_fullWidth]: fullWidth,
        [styles.wrapper_error]: error,
      })}
      labels={lang === 'ru' ? localeRu : localeEn}
      defaultCountry={defaultCountry}
    />
  );
};
