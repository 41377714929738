import useTranslation from 'next-translate/useTranslation';
import { useLocationsListQuery } from '@/modules/locations';
import { ARRAY_OF_RANGES } from '../consts';

const specialKeys = new Set(['color', 'search', 'city', 'district']);
const noTranslationKeys = new Set(['size', 'brand', 'age_restriction']);

export const useTranslateFilterTags = (filters) => {
  const { t } = useTranslation();
  const { data } = useLocationsListQuery({ refetchOnMount: false });

  if (!data?.data) return [];

  const filterNonEmptyValues = (filterValues) => Object.fromEntries(
    Object.entries(filterValues || {}).filter(
      ([, value]) => (
        (Array.isArray(value) && value.length && !value.includes(0))
        || (typeof value === 'string' && value.trim() !== '')
      ),
    ),
  );

  const mapLocationFilters = () => {
    const locationLookup = data.data.reduce((acc, city) => {
      acc.city[city.id] = t(`locations:${city.codename}.${city.codename}`);
      city.districts.forEach((district) => {
        acc.district[district.id] = t(`locations:${city.codename}.districts.${district.codename}`);
      });
      return acc;
    }, { city: {}, district: {} });

    return (ids, type) => ids.map((id) => locationLookup[type][id]).filter(Boolean);
  };

  const determineFilterType = (key) => {
    if (ARRAY_OF_RANGES.includes(key)) return 'ranges';
    if (noTranslationKeys.has(key)) return 'notranslation';
    if (key === 'operation_type') return 'operation_type';
    return specialKeys.has(key) ? key : 'checkbox_list';
  };

  const translateLocation = mapLocationFilters();

  const formatValueWithUnit = (key, value) => {
    if (key === 'min_price' || key === 'max_price') return value;
    const unit = t(`filters:range.${key.replace(/^(min|max)_/, '')}.unit`);
    return `${value} ${unit}`;
  };

  const filterHandlers = {
    ranges: (key, value) => ({ title: t(`filters-components:ranges.${key}`), codename: key, value: formatValueWithUnit(key, value) }),
    search: (key, value) => ({ title: t(`filters:${key}.title`), codename: key, value: value.join(', ') }),
    color: (key, value) => {
      const translatedColors = value.map((color) => t(`common:colors.${color}`));
      return { title: t(`filters:${key}.title`), codename: key, value: translatedColors.join(', ') };
    },
    city: (key, value) => {
      const locations = translateLocation(value, 'city');
      const titleKey = locations.length > 1 ? 'filters-components:tags.cities' : 'filters-components:tags.city';
      return { title: t(titleKey), codename: key, value: locations.join(', ') };
    },
    district: (key, value) => {
      const locations = translateLocation(value, 'district');
      const titleKey = locations.length > 1 ? 'filters-components:tags.districts' : 'filters-components:tags.district';
      return { title: t(titleKey), codename: key, value: locations.join(', ') };
    },
    checkbox_list: (key, value) => ({
      title: t(`filters:checkbox-list.${key}.title`),
      codename: key,
      value: value.map((v) => t(`filters:checkbox-list.${key}.options.${v}`)).join(', '),
    }),
    notranslation: (key, value) => ({
      title: t(`filters:checkbox-list.${key}.title`),
      codename: key,
      value: value.join(', '),
    }),
    operation_type: (key, value) => ({
      title: t('filters-components:operation_type_title._'),
      codename: key,
      value: t(`filters:checkbox-list.${key}.options.${value}`),
    }),
  };

  return Object.entries(filterNonEmptyValues(filters)).map(([key, value]) => {
    const filterType = determineFilterType(key);
    return filterHandlers[filterType](key, value);
  });
};
