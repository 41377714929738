import {
  Dispatch, SetStateAction, useEffect, useMemo,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import {
  FormProvider, SubmitHandler, useForm,
} from 'react-hook-form';
import { isEqual } from 'lodash';
import { Button } from '@/modules/shared/components';
import type { Company } from '@/modules/company/types';
import { useCorrectCompany, useCreateCompany } from '@/modules/company/mutations';
import useUserAccount from '@/hooks/users/useUserAccount';
import s from './become-company.module.scss';
import { CompanyInfo } from './company-info.component';
import type { BecomeCompanyFormType } from './types';
import { VerificationSection } from './verification-section.component';

type BecomeCompanyFormProps = {
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
  isResubmit: boolean;
  companyData: Company | undefined;
  isLoading: boolean;
};

export const BecomeCompanyForm = ({
  setIsSuccess, isResubmit, companyData, isLoading,
}: BecomeCompanyFormProps) => {
  const { t } = useTranslation();
  const { mutate: create, isSuccess, isPending } = useCreateCompany();
  const { userData } = useUserAccount();
  const {
    mutate: correct, isSuccess: correctSuccess, isPending: correctPending,
  } = useCorrectCompany();

  const defaultValues = useMemo(() => {
    if (companyData) {
      return {
        logo: companyData?.logo,
        title: companyData?.name,
        description: companyData?.description,
        tax_id: Number(companyData?.tax_id),
      };
    }
    return {};
  }, [companyData]);

  const formMethods = useForm<BecomeCompanyFormType>({ defaultValues });
  const {
    handleSubmit,
    watch,
    formState: { isValid, errors, isDirty },
  } = formMethods;

  const onsubmit: SubmitHandler<BecomeCompanyFormType> = async (data) => {
    if (userData) {
      if (isResubmit) {
        const watchedValues = watch();
        const filteredData: Partial<BecomeCompanyFormType> = Object.keys(data).reduce((acc, key) => {
          const currentValue = watchedValues[key];
          const defaultValue = defaultValues[key];

          if (
            typeof currentValue === 'object' && typeof defaultValue === 'object'
              ? !isEqual(currentValue, defaultValue)
              : currentValue !== defaultValue
          ) {
            acc[key] = currentValue;
          }
          return acc;
        }, {});
        correct({
          ...filteredData,
          name: filteredData.title || companyData?.name,
          user: userData.id,
        });
      } else {
        create({
          user: userData.id,
          name: data.title,
          description: data.description,
          logo: data.logo as File | null | undefined,
          tax_id: data.tax_id,
          // временно закомментировано
          // при возобновлении обработки документов добавить также в correct выше
          // documents: data.documents,
        });
      }
    }
  };

  useEffect(() => {
    if (isSuccess || correctSuccess) setIsSuccess(true);
  }, [isSuccess, correctSuccess]);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onsubmit)}
        autoComplete="off"
        id="form-become-company"
        className={s.form}
      >
        <CompanyInfo defaultLogo={companyData?.logo} isLoading={isLoading} />
        <VerificationSection />
        {/* временно закомментировано
        <Documents /> */}
        <Button
          type="submit"
          appearance="primary"
          buttonColor="blue"
          buttonSize="s"
          disabled={
            !isValid
            || !isDirty
            || !!Object.keys(errors).length
            // || watch('documents').length < 3
            || isPending
            || correctPending
            || !userData?.is_email_verified
          }
          className={s.submit}
        >
          {(isPending || correctPending) ? t('common:submitting') : t('userAccount:become_company.send')}
        </Button>
      </form>
    </FormProvider>
  );
};
