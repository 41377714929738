import React, {
  useCallback, useEffect, useState, useMemo,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { useMatchMedia } from '../../hooks/use-match-media.hook';
import { SvgIcon } from '../svg-icon';
import { Button } from '../button';
import { ModalMobile } from '../modal';
import { RadioButton } from '../inputs';
import s from './sorting.module.scss';

export type SortingProps = {
  value: string;
  setValue: (value: string) => void;
  options: Array<{ value: string; label: string }>;
  showBackButton?: boolean;
  shouldDisabled?: boolean; // если нужно блокировать кнопку "применить", когда selectedValue === value
  rightPosition?: boolean;
};

export const Sorting = ({
  value,
  setValue,
  options,
  showBackButton = true,
  shouldDisabled = false,
  rightPosition = false,
}: SortingProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useMatchMedia();
  const [tempValue, setTempValue] = useState(value);

  useEffect(() => {
    if (!isOpen) return;

    const close = (e: MouseEvent) => {
      if (Array.from((e.target as HTMLButtonElement).classList).includes(s.sorting_button)) {
        return;
      }
      if (isMobile && isOpen) {
        return;
      }

      setIsOpen(false);
    };

    window.addEventListener('click', close);
    return () => window.removeEventListener('click', close);
  }, [isOpen]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleApply = useCallback(() => {
    setIsOpen(false);
    setValue(tempValue);
  }, [tempValue]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setTempValue(event.target.value);
  }, []);

  const handleClick = (val: string) => {
    setValue(val);
    setIsOpen(false);
  };

  const filteredOptions = useMemo(() => options.filter((option) => option.value !== value), [options, value]);
  const [selectedLabel, selectedOption] = useMemo(() => {
    const optionObject = options.find((option) => option.value === value);

    return [optionObject?.label || '', optionObject];
  }, [options, value]);

  const disabled = selectedOption?.value === tempValue;

  return (
    <div className={s.container}>
      <Button
        appearance="primary"
        buttonColor="white"
        buttonSize="xs"
        onClick={() => setIsOpen(!isOpen)}
        data-testid="sort_btn"
        className={s.sorting_button}
      >
        <SvgIcon name="sorting" />
        {value === 'default' ? t('common:sorting.title') : selectedLabel}
        <SvgIcon name={isOpen ? 'arrow-up' : 'arrow-down'} />
      </Button>
      {isMobile ? (
        <ModalMobile
          open={isOpen}
          title={t('common:sorting.title')}
          onClose={handleClose}
          showCloseButton
          showBackButton={showBackButton}
        >
          <div className={s.modal_content}>
            <div className={s.options}>
              {options.map((option) => (
                <label
                  key={option.value}
                  data-testid={`sort_option_${option.value}`}
                >
                  <RadioButton
                    radioColor="green"
                    name="sorting"
                    value={option.value}
                    checked={tempValue === option.value}
                    onChange={handleChange}
                  />
                  {option.label}
                </label>
              ))}
            </div>
            <Button
              appearance="primary"
              buttonColor="green"
              buttonSize="s"
              onClick={handleApply}
              disabled={disabled && shouldDisabled}
            >
              {t('common:apply')}
            </Button>
          </div>
        </ModalMobile>
      ) : (
        <div className={clsx(s.modal, {
          [s.hidden]: !isOpen,
          [s.modal_right]: rightPosition,
        })}
        >
          <div className={s.options}>
            <span className={s.active}>
              <SvgIcon name="check16" />
              {selectedLabel}
            </span>
            {filteredOptions.map((option) => (
              <span
                key={option.value}
                data-testid={`sort_option_${option.value}`}
                onClick={() => handleClick(option.value)}
                className={s.label}
              >
                {option.label}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
