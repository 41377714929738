import {
  useReducer, useMemo, Dispatch, createContext, useContext, useCallback,
} from 'react';
import { useUpdateEffect } from '@react-hookz/web';
import { isEqual } from 'lodash';

const stringOrUndefinedToState = (v: string) => v || undefined;
const booleanToState = (v: string) => Boolean(v);
const arrayToState = (v: string | string[]) => (Array.isArray(v) ? v : v ? [v] : []);
const arrayToTagState = (v: string | string[]) => (Array.isArray(v) ? v : v ? [v] : []);
const arrayToStateNumber = (v: string | string[]) => (Array.isArray(v)
  ? v.map((val) => Number(val)) : Number(v) ? [Number(v)] : []);


const MAP_QUERY_TO_INITIAL_FIELDS = {
  swimming_pool: booleanToState,
  deposit: booleanToState,
  delivery: booleanToState,
  child_seat: booleanToState,
  phone_mount: booleanToState,
  phone_charger: booleanToState,
  availability_of_loaders: booleanToState,
  audio_system: booleanToState,
  max_price: stringOrUndefinedToState,
  min_price: stringOrUndefinedToState,
  max_mileage: stringOrUndefinedToState,
  min_mileage: stringOrUndefinedToState,
  max_year: stringOrUndefinedToState,
  min_year: stringOrUndefinedToState,
  max_engine_capacity: stringOrUndefinedToState,
  min_engine_capacity: stringOrUndefinedToState,
  max_living_space: stringOrUndefinedToState,
  min_living_space: stringOrUndefinedToState,
  max_land_area: stringOrUndefinedToState,
  min_land_area: stringOrUndefinedToState,
  city: arrayToStateNumber,
  district: arrayToStateNumber,
  search: arrayToState,
  engine_type: arrayToState,
  transmission: arrayToState,
  operation_type: arrayToState,
  permitted_construction: arrayToState,
  completion_status: arrayToState,
  ownership_right: arrayToState,
  allows_pets: arrayToState,
  color: arrayToState,
  rental_type: arrayToState,
  engine_capacity: arrayToState,
  number_of_rooms: arrayToState,
  duration: arrayToState,
  type_housing: arrayToState,
  rules: arrayToState,
  drive: arrayToState,
  condition: arrayToState,
  vehicle_type: arrayToState,
  additional_options: arrayToState,
  announcement_source: arrayToState,
  manufacturer: arrayToState,
  service_type: arrayToState,
  schedule: arrayToState,
  experience: arrayToState,
  gender: arrayToState,
  payment: arrayToState,
  brand: arrayToState,
  state: arrayToState,
  size: arrayToState,
  transfer: arrayToState,
  age_restriction: arrayToTagState,
  guide: arrayToState,
  language: arrayToState,
  duration_of_excursion: arrayToState,
};

export type StateValues = string | string[] | number[] | boolean | undefined | null;

type MapQueryFields = typeof MAP_QUERY_TO_INITIAL_FIELDS;

export type FilterQuery = keyof MapQueryFields;

export type FormState<T extends FilterQuery> = {
  value: Partial<Record<T, ReturnType<MapQueryFields[T]>>>,
  setter: Partial<Record<T, (Dispatch<ReturnType<MapQueryFields[T]>> & {
    reset: () => void
  })>>
  query: Partial<Record<T, ReturnType<MapQueryFields[T]> | undefined>>,
};

function reducer<T extends FilterQuery>(state, action: {
  type: 'SET_PROPERTY';
  payload: {
    name: T,
    value: StateValues
  };
} | {
  type: 'SET_STATE';
  payload: Record<T, StateValues>;
}) {
  switch (action.type) {
    case 'SET_PROPERTY':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'SET_STATE':
      return { ...action.payload };
    default:
      return state;
  }
}

/**
 * Тут дизейблим потому что нельзя использовать хук без провайдера контекста, а в провайдере это значение всегда будут
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const filterFormContext = createContext<FormState<FilterQuery>>({});

export const useFilterFormContext = <T extends FilterQuery = FilterQuery>() => useContext<FormState<T>>(filterFormContext);

export const useFilterFormState = <T extends FilterQuery = FilterQuery>(
  fields: T[],
  queryState: Record<string, StateValues> | undefined,
  fetchData: (query: Record<T, ReturnType<MapQueryFields[T]> | undefined>) => void,
  { localCityId }: {
    localCityId?: number
  },
) => {
  const mappedQueryState = useMemo(() => fields.reduce((memo, value) => {
    if (!MAP_QUERY_TO_INITIAL_FIELDS[value]) return memo;

    memo[value] = MAP_QUERY_TO_INITIAL_FIELDS[value](queryState?.[value] as string);
    return memo;
  }, {} as Record<T, StateValues>), [fields, queryState]);

  const [state, dispatch] = useReducer<typeof reducer<T>>(
    reducer,
    mappedQueryState,
  );

  useUpdateEffect(() => {
    dispatch({ type: 'SET_STATE', payload: mappedQueryState });
  }, [
    mappedQueryState,
    queryState?.category,
    queryState?.sub_category,
    queryState?.under_sub_category,
  ]);

  const form = useMemo(() => Object
    .keys(state)
    .reduce((memo, property) => {
      const setState = (value) => dispatch({ type: 'SET_PROPERTY', payload: { name: property as T, value } });
      setState.reset = () => setState(MAP_QUERY_TO_INITIAL_FIELDS[property]?.(undefined));

      const query = Array.isArray(state[property])
        ? (state[property].length ? state[property] : undefined)
        : state[property] || undefined;

      memo.setter[property] = setState;
      memo.query[property] = query;

      return memo;
    }, {
      value: state,
      setter: {},
      query: {},
    } as FormState<T>), [state]);

  const handleResetFilters = useCallback(() => {
    fetchData(Object.entries(state).reduce((memo, [key]) => {
      memo[key] = undefined;
      return memo;
    }, {} as Parameters<typeof fetchData>[0]));
  }, [fetchData, state]);

  const hasNotAppliedFilters = useMemo(
    () => !isEqual(mappedQueryState, state),
    [mappedQueryState, state],
  );

  const hasAppliedFilters = useMemo(
    () => {
      const newData = { ...mappedQueryState };
      if ('city' in newData && Array.isArray(newData.city) && newData.city.length === 1 && newData.city[0] === localCityId) {
        newData.city = [];
      }

      return Object.values(newData).some((value) => (Array.isArray(value) ? !!value.length : !!value));
    },
    [mappedQueryState, localCityId],
  );

  const handleApplyFilter = useCallback(() => {
    fetchData({
      ...form.query,
      city: form.value['city' as const],
      district: form.value['district' as const],
    });
  }, [fetchData, form.query, form.value]);

  const handleRemoveFilter = useCallback((filterKey: FilterQuery) => {
    fetchData({
      ...state,
      [filterKey]: undefined,
    });
  }, [fetchData, state]);

  return [
    form,
    hasNotAppliedFilters,
    hasAppliedFilters,
    handleResetFilters,
    handleApplyFilter,
    handleRemoveFilter,
  ] as const;
};
