import useTranslation from 'next-translate/useTranslation';
import { CreationDescription, CreationTitle } from '@/modules/shared/components';
import type { CompanyLogo } from '@/modules/company/types';
import useUserAccount from '@/hooks/users/useUserAccount';
import s from './become-company.module.scss';
import { CompanyLogoField } from './company-logo.component';
import { Email } from '../credentials/email.component';

type CompanyInfoProps = {
  defaultLogo: CompanyLogo | undefined;
  isLoading: boolean;
};

export const CompanyInfo = ({ defaultLogo, isLoading }: CompanyInfoProps) => {
  const { t } = useTranslation('userAccount');
  const { userData, refetch } = useUserAccount();

  return (
    <section className={s.field}>
      <h2>{t('become_company.company_info._')}</h2>
      <CompanyLogoField defaultLogo={defaultLogo} isLoading={isLoading} />
      <CreationTitle
        title={t('become_company.company_info.name')}
        placeholder={t('become_company.company_info.name_placeholder')}
        required={false}
        tip={t('become_company.company_info.name_tip')}
      />
      <CreationDescription
        max_length={150}
        title={t('become_company.company_info.description')}
        placeholder={t('become_company.company_info.description_placeholder')}
        required={false}
        heightScrollDesktop={150}
        heightScrollMobile={125}
      />
      <Email
        email={userData?.email}
        refetch={refetch}
        emailVerified={!!userData?.is_email_verified}
        isCreateCompanyPage
      />
    </section>
  );
};
