import { InputText, SvgIcon } from '@/modules/shared/components';
import useTranslation from 'next-translate/useTranslation';

import {
  useEffect, useRef, useState,
} from 'react';
import styles from './search-text.module.scss';

type SearchWithinFilterProps = {
  value: string[];
  onChange: (value: string[]) => void;
  onInput: (value: string) => void;
  placeholder?: string;
  foundValue?: boolean;
};
export const SearchWithinFilter = ({
  onChange, onInput, value, placeholder, foundValue,
}: SearchWithinFilterProps) => {
  const [innerValue, setInnerValue] = useState<string>('');
  const bottomSensorRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!bottomSensorRef.current || !value.length) return;
    bottomSensorRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  }, [value.length]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputWrapper}>
        <InputText
          onChange={(e) => {
            const inputValue = e.target.value;
            setInnerValue(inputValue);
            onInput(inputValue);
          }}
          value={innerValue}
          name="search"
          className={styles.input}
          placeholder={placeholder || t('filters-components:search-text.placeholder')}
        />
        {!!innerValue && (
          <button
            type="button"
            className={styles.addBtn}
            onClick={() => {
              onInput('');
              setInnerValue('');
            }}
          >
            <SvgIcon name="close" />
          </button>
        )}
      </div>
      {!foundValue && <p className={styles.nothingFound}>{`${t('common:nothingFound')} :(`}</p>}

      {!!value?.length && (
        <>
          <div className={styles.items}>
            {value.map((item) => (
              <div key={item}>
                <div className={styles.itemsName}>{item}</div>
                <button onClick={() => onChange(value.filter((v) => v !== item))}><SvgIcon name="close" /></button>
              </div>
            ))}
          </div>
          <div ref={bottomSensorRef} />
        </>
      )}

    </div>
  );
};
