import React from 'react';
import type { AppContext } from 'next/app';

import getConfig from 'next/config';
import { isServerSide } from '../../helpers';
import { Feature, Features } from '../types';
import { featureContext } from '../context';
import { featuresSchema } from '../schema';

const { serverRuntimeConfig: { featureTogglingUrl } } = getConfig();

const loadFeatures = async () => {
  try {
    if (!featureTogglingUrl) return [];

    const response = await fetch(featureTogglingUrl);
    if (!response.ok) {
      throw new Error('Failed to fetch feature flags');
    }

    return await featuresSchema.validate(await response.json());
  } catch (e) {
    console.error('[feature-toggling] Loading or parsing error', e);
    return [];
  }
};

let singletonFeatures: Map<string, Feature> | undefined;

export const withFeatureFlag = (Component) => {
  const Wrapper = ({ features, ...props }: { features?: Features }) => {
    if (!singletonFeatures || isServerSide()) {
      singletonFeatures = new Map(features?.map((feature) => [
        feature.name,
        {
          ...feature,
        }]));
    }

    return (
      <featureContext.Provider value={singletonFeatures}>
        <Component {...props} />
      </featureContext.Provider>
    );
  };

  Wrapper.getInitialProps = async (app: AppContext) => {
    const [initialPageProps, staticPageProps, features] = await Promise.allSettled([
      Component.getInitialProps?.(app) as Promise<object>,
      Component.getStaticProps?.(app) as Promise<object>,
      loadFeatures(),
    ]);

    let props = {};
    if (initialPageProps.status === 'fulfilled') {
      props = {
        ...props,
        ...initialPageProps.value,
      };
    }
    if (staticPageProps.status === 'fulfilled') {
      props = {
        ...props,
        ...staticPageProps.value,
      };
    }
    if (features.status === 'fulfilled') {
      props = {
        ...props,
        features: features.value,
      };
    }

    return props;
  };
  Wrapper.displayName = `withFeatureToggling(${Wrapper.displayName || Wrapper.name})`;

  return Wrapper;
};
