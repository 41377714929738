import useTranslation from 'next-translate/useTranslation';
import { useMatchMedia } from '@/modules/shared/hooks';
import { SvgIcon } from '@/modules/shared/components';
import styles from './have-nothing.module.scss';

export const HaveNothing = () => {
  const { t } = useTranslation('favourites');
  const { isDesktop } = useMatchMedia();

  return (
    <>
      {isDesktop ? (
        <div className={styles.have_nothing}>
          <h3>{t('nothingHaveTitle')}</h3>
          <p>{t('nothingHaveDescription')}</p>
        </div>
      ) : (
        <div className={styles.have_nothing}>
          <h4>{t('nothingHaveTitleMobile')}</h4>
          <p>{t('nothingHaveDescriptionMobile')}</p>
          <SvgIcon
            name="favourite-icon"
            className={styles.have_nothing_icon}
          />
        </div>
      )}
    </>
  );
};
