export const POSSIBLE_CITIES = [
  'phang_nga',
  'bangkok',
  'phuket',
  'pattaya',
  'samui',
  'krabi',
  'pangan',
  'allcities',
];
