import {
  memo, useMemo, useState, useEffect,
} from 'react';

import { FormState } from '@/modules/shared/hooks';

import { CheckboxList } from '../../components/checkbox-list';

import { FiltersMap } from '../../types';
import { withDynamicFieldFormGuard } from '../../hoc/withDynamicFieldFormGuard';
import { FilterRow } from '../../components/filter-row';
import { SearchWithinFilter } from '../../components/search-within-filter.component';
import { withErrorBoundary } from '../../hoc/withErrorBoundary';


type CheckboxSearchListProps<T extends FiltersMap['checkbox_list'] = FiltersMap['checkbox_list']> = {
  form: FormState<T['name']>;
  filter: FiltersMap['checkbox_list'];
};

const MAX_VISIBLE_ITEMS = 5;

export const CheckboxSearchList = withErrorBoundary(withDynamicFieldFormGuard<CheckboxSearchListProps>(memo(({
  t,
  form,
  filter: { choices, name },
}) => {
  const value = form.value[name] || [];
  const onChange = form.setter[name]!;
  const [minimized, setMinimized] = useState<boolean>(true);
  const [foundValue, setFoundValue] = useState<boolean>(true);
  const [valueInput, setValueInput] = useState<string>('');
  const [filteredChoicesList, setFilteredChoicesList] = useState<string[]>(choices);

  const items = useMemo(() => {
    const valueIndex = new Set();

    const createItem = (code: string) => ({
      label: t(`filters:checkbox-list.${name}.options.${code}`, null, { fallback: code }),
      value: code,
    });

    const filterChoices = (
      choicesArray: string[],
      checkValueInput: boolean,
    ): { label: string; value: string }[] => choicesArray.reduce((memoValue, code) => {
      if (minimized && memoValue.length >= Math.max(MAX_VISIBLE_ITEMS, value.length)) return memoValue;

      if (valueIndex.has(code)) return memoValue;

      if (checkValueInput && valueInput.trim()
          && !code.toLowerCase().startsWith(valueInput.trim().toLowerCase())) {
        return memoValue;
      }

      memoValue.push(createItem(code));
      valueIndex.add(code);
      return memoValue;
    }, [] as { label: string; value: string }[]);


    let filteredChoices = filterChoices(choices, true);

    setFoundValue(filteredChoices.length > 0);

    if (filteredChoices.length === 0) {
      filteredChoices = filterChoices(choices, false);
    }

    return filteredChoices;
  }, [choices, minimized, name, t, valueInput]);


  useEffect(() => {
    setFilteredChoicesList(items.length < MAX_VISIBLE_ITEMS && items.length !== 0
      ? choices.slice(0, MAX_VISIBLE_ITEMS)
      : choices);
  }, [items, choices]);


  if (!t(`filters:checkbox-list.${name}.title`, null, {
    default: false,
  }) || !items.length) {
    console.log(`Unknown filters:checkbox-list.${name}.title`);
    return null;
  }

  return (
    <FilterRow title={t(`filters:checkbox-list.${name}.title`)}>
      <SearchWithinFilter
        onChange={onChange}
        onInput={setValueInput}
        value={value}
        placeholder={t(`filters:checkbox-list.${name}.placeholder`)}
        foundValue={foundValue}
      />

      <CheckboxList
        value={value}
        name={name}
        minimized={minimized}
        showMoreOnClick={() => setMinimized((v) => !v)}
        items={items}
        choices={filteredChoicesList}
        onChange={onChange}
      />
    </FilterRow>
  );
}, (prev, next) => (
  prev.form.value[prev.filter?.name] === next.form.value[next.filter?.name]
    && prev.filter === next.filter
    && prev.t === next.t
))));
