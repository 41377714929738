import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import { ProductJsonLd } from 'next-seo';
import { Box } from '@mui/material';
import {
  CardSystemInformation,
  ItemLoader,
  ShareButton,
  GalleryBlock,
} from '@/modules/shared/components';
import { IAd } from '@/modules/ads/types';
import Cookie from 'js-cookie';
import { ProductInfo } from '@/modules/ads';
import { useCityPrefix } from '@/context/CityPrefixContext';
import { useCategoryName } from '@/modules/categories/hooks';
import { useUserProfile } from '@/modules/user-profile/queries';
import { useAdsListQuery } from '@/modules/ads/queries';
import { useMatchMedia } from '@/modules/shared/hooks';
import { ComplaintModal } from '@/modules/complaint';
import { useAuthContext, useOpenAuthModal } from '@/modules/auth';
import { BreadcrumbType } from '@/modules/shared/components/breadcrumbs';
import { FavoriteButton } from '@/modules/favorites';
import { Seo } from '@/modules/seo';
import { UserTypeEnum } from '@/modules/users';
import { useSeo } from '@/modules/app/components/ad-page/use-seo';
import { AdHeadline } from '../ad-headline';
import { AdTitleMobile } from './ad-header';
import { MobileHeader } from '../header';
import { ContactsBlock } from './contacts-block';
import { AllSellerAds } from './all-seller-ads';
import { SimilarAds } from './similar-ads.component';
import styles from './ad-page.module.scss';

interface Props {
  ad: IAd;
  host: string
}
// FIXME: после редизайна нужно спустить модалку ниже, тк при открытии/закрытии перерендеривается вся страница

export const AdPage = ({ ad, host }: Props) => {
  const { t, lang } = useTranslation('');
  const openAuthModal = useOpenAuthModal();
  const { isDesktop, isTablet, isMobile } = useMatchMedia();
  const { isAuthenticated } = useAuthContext();
  const [complaintButtonClick, setComplaintButtonClick] = useState(false);
  const [complaintModal, setComplaintModal] = useState(false);
  const [allowedComplain, setAllowedComplain] = useState(true);
  const { cityPrefix } = useCityPrefix();
  const [getCategory] = useCategoryName();

  const { data: adOwnerProfile } = useUserProfile(ad.user);
  const { data: adOwnerAdsList } = useAdsListQuery({
    filters: { user: ad.user },
    pageSize: 5,
    page: 1,
    order: '-created_at',
  });

  const adheaderProps = useMemo(
    () => ({
      data: ad,
      breadcrumbs: [
        {
          title: getCategory(ad.category.codename),
          codename: ad.category.codename,
          pathname: `/${lang}/${cityPrefix}/catalog/${ad.category.codename}`,
        },
        ad.sub_category && {
          title: getCategory(ad.category.codename, ad.sub_category.codename),
          codename: ad.sub_category.codename,
          pathname: `/${lang}/${cityPrefix}/catalog/${ad.category.codename}/${ad.sub_category.codename}`,
        },
        ad.under_sub_category && {
          title: getCategory(ad.category.codename, ad.sub_category.codename, ad.under_sub_category.codename),
          codename: ad.under_sub_category.codename,
          pathname: `/${lang}/${cityPrefix}/catalog/${ad.category.codename}/${ad.sub_category.codename}/${ad.under_sub_category.codename}`,
        },
        {
          title: ad.title,
          codename: ad.title,
          pathname: ad.title,
        },
      ].filter(Boolean) as BreadcrumbType[],
    }),
    [ad, cityPrefix, getCategory, lang],
  );

  useEffect(() => {
    const userIdCookie = Cookie.get('user_id');
    const reviewerId = userIdCookie !== null ? parseInt(String(userIdCookie), 10) : null;
    setAllowedComplain(!(ad.user === reviewerId));
  }, [ad]);

  useEffect(() => {
    if (isMobile && isAuthenticated && complaintButtonClick) {
      setComplaintModal(true);
    }
  }, [complaintButtonClick, isAuthenticated, isMobile]);

  const handleComplaint = useCallback(() => {
    if (isTablet || isDesktop || (isMobile && isAuthenticated)) {
      setComplaintModal(true);
    } else if (isMobile && !isAuthenticated) {
      setComplaintButtonClick(true);
      openAuthModal('login');
    }
  }, [isTablet, isDesktop, isMobile, isAuthenticated, openAuthModal]);

  const seoProps = useSeo(ad, host);

  return (
    <section className={styles.ad_page}>
      <Seo {...seoProps} />
      <ProductJsonLd
        productName={ad.title}
        description={ad.description}
        brand={ad.specifications?.brand ?? undefined}
        images={ad.images?.map((el) => el.thumbnail || el.image || '')}
        offers={{
          price: ad.price_type === 'for_money' ? ad.price : 0,
          priceCurrency: 'THB',
        }}
        aggregateRating={{
          ratingValue: ad.user_data?.average_rating,
          reviewCount: ad.user_data?.ratings_count,
        }}
      />
      {!isDesktop && (
        <MobileHeader
          rightContent={(
            <div className={styles.share_and_like}>
              <FavoriteButton id={ad.id} type="ads" />
              <ShareButton title={t('adv:linkCopied')} />
            </div>
          )}
          showOnCloseButton={false}
        />
      )}
      {isDesktop && (
        <AdHeadline
          data={adheaderProps.data}
          breadcrumbs={adheaderProps.breadcrumbs}
        />
      )}
      <section className={styles.ad_block}>
        <div className={styles.images_block}>
          <GalleryBlock images={ad.images} />
        </div>
        {!isDesktop && <AdTitleMobile title={ad.title} price={ad.price} priceType={ad.price_type!} />}
        {adOwnerProfile ? (
          <ContactsBlock
            adId={ad.id}
            adUserId={ad.user}
            userData={adOwnerProfile}
          />
        ) : (
          <Box display="flex" justifyContent="center">
            <ItemLoader isLoading size={50} />
          </Box>
        )}
        <ProductInfo
          data={ad}
          user_type={adOwnerProfile?.company_id ? UserTypeEnum.Company : UserTypeEnum.Individual}
        />
        <CardSystemInformation
          id={ad.id}
          views={ad.views}
          created_at={ad.created_at}
          allowedComplain={allowedComplain}
          handleComplaint={handleComplaint}
        />
      </section>
      {adOwnerAdsList && adOwnerAdsList?.results?.length > 0 && (
        <AllSellerAds
          ad={ad}
          adOwnerAdsList={adOwnerAdsList.results}
          companyId={adOwnerProfile?.company_id}
        />
      )}
      <SimilarAds adId={ad.id} />
      {allowedComplain && (
        <ComplaintModal
          isOpen={complaintModal}
          setModalClosed={setComplaintModal}
          onClose={() => setComplaintModal(false)}
          recipient={ad?.user}
          id={ad?.id}
          type="ad"
        />
      )}
    </section>
  );
};
