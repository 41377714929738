import React, { FC, ReactNode } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { NextSeo } from 'next-seo';
import s from './error-page.module.scss';

interface ErrorPageProps {
  button: ReactNode;
  content: ReactNode;
  children: ReactNode;
}

const ErrorPage: FC<ErrorPageProps> = ({ button, content, children }) => {
  const { t } = useTranslation('');

  return (
    <>
      <NextSeo
        title={t('notFound:pageNotFound.title')}
        nofollow
        noindex
      />
      <div className={s.container}>
        {children}
        <div className={s.content}>{content}</div>
        {button}
      </div>
    </>
  );
};

export default ErrorPage;
