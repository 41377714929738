export const ARRAY_OF_RANGES = [
  'min_year',
  'max_year',
  'min_price',
  'max_price',
  'min_mileage',
  'max_mileage',
  'min_land_area',
  'max_land_area',
  'min_living_space',
  'max_living_space',
  'min_engine_capacity',
  'max_engine_capacity',
];
