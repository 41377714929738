import React, {
  ChangeEvent, FC, KeyboardEvent, RefObject,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { stopEvent } from '@/modules/shared/helpers';
import { SvgIcon } from '@/modules/shared/components';
import type { SearchProps } from './search-bar.component';
import styles from './search-input.module.scss';


type InputProps = Omit<SearchProps, 'isInputFocused' | 'onSearchFilter'> & {
  value: string;
  setSearch: (value: string) => void;
  onSearch: (value: string) => void;
  inputRef: RefObject<HTMLInputElement>;
};


export const SearchInput: FC<InputProps> = ({
  value,
  setSearch,
  onSearch,
  inputRef,
  setIsInputFocused,
  text,
  hasSearchButton,
  customStyles = {},
  searchOnType,
  isClickOutside,
}) => {
  const { t } = useTranslation('');
  const handleSearch = () => {
    onSearch(value);
    setIsInputFocused(false);
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const keys = ['Enter', 'Search', 'Go', 'Done'];
    if (keys.includes(e.key)) {
      handleSearch();
    }
  };

  const handleFocus = () => setIsInputFocused(true);
  const handleBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    if (isClickOutside) {
      setIsInputFocused(false);
      event.target.style.pointerEvents = 'none';
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearch(newValue);
    if (searchOnType) {
      onSearch(newValue);
    }
  };
  const clearSearch = () => {
    setSearch('');
    onSearch('');
  };

  return (
    <div className={clsx(styles.search_input, customStyles.searchBar)}>
      <label
        className={clsx(styles.field, customStyles.field)}
        onClick={handleFocus}
      >
        <span className={styles.icon}>
          <SvgIcon name="search" />
        </span>
        <input
          data-testid="search_field"
          type="text"
          inputMode="search"
          value={value}
          ref={inputRef}
          onChange={handleChange}
          onBlur={(event) => handleBlur(event)}
          onFocus={(event) => event.target.style.pointerEvents = 'auto'}
          onKeyDown={handleKeyDown}
          placeholder={text?.placeholder || t('common:search')}
        />
        {value?.length > 0 && (
          <button
            type="button"
            className={clsx(styles.button_delete, customStyles.button_delete)}
            onMouseDown={stopEvent}
            onClick={clearSearch}
          >
            <SvgIcon name="x-close" />
          </button>
        )}
      </label>
      {hasSearchButton && (
        <button
          type="button"
          data-testid="search_button"
          className={styles.search_button}
          onMouseDown={stopEvent}
          onClick={handleSearch}
        >
          {text?.button || t('common:searchBarButton')}
        </button>
      )}
    </div>
  );
};
