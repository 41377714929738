import React, { createContext, useContext, ReactNode } from 'react';
import type { ShareProfileModeType } from '../types';

interface ShareProfileContextProps {
  mode: ShareProfileModeType;
}

const ShareProfileContext = createContext<ShareProfileContextProps>({
  mode: 'view',
});

interface ShareProfileProviderProps {
  children: ReactNode;
  mode?: ShareProfileModeType;
}

export const ShareProfileProvider = ({ children, mode = 'view' } : ShareProfileProviderProps) => (
  <ShareProfileContext.Provider value={{ mode }}>
    {children}
  </ShareProfileContext.Provider>
);

export const useShareProfileContext = () => useContext(ShareProfileContext);
