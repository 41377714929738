import { useCallback } from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { FieldError, useFormContext } from 'react-hook-form';
import { InputText } from '@/modules/shared/components/inputs/input-text';
import { COMPANY_CONTACTS, MEDIA_LINKS, MEDIA_USERNAMES } from '@/modules/shared/const';
import { SvgIcon } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { SocialNetworks } from '@/modules/company/types';
import { getMediaUsername } from '@/modules/shared/helpers';
import s from '../credentials.module.scss';
import styles from './company-credentials.module.scss';
import sField from '../field.module.scss';
import { CompanyCredentialsForm } from '../types';

export const Media = ({ media }: { media: SocialNetworks }) => {
  const { t } = useTranslation();
  const {
    register, watch, formState: { errors }, clearErrors, setValue, setError,
  } = useFormContext<CompanyCredentialsForm>();
  const { isDesktop } = useMatchMedia();

  const handleBlur = useCallback((field: string) => {
    let inputValue = watch(field as keyof CompanyCredentialsForm);
    if (field === 'whatsapp' && watch('whatsapp')) {
      inputValue = (inputValue as string).replace('+', '');
    }

    if (inputValue
      && !MEDIA_USERNAMES[field]
        .test(getMediaUsername((inputValue as string).trim(), MEDIA_LINKS[field]))) {
      setError(field as keyof CompanyCredentialsForm, {
        type: 'incorrect',
        message: t('forms:validationErrors.username_format'),
      });
      return;
    }
    clearErrors('social_networks'[field]);
    clearErrors(field as keyof CompanyCredentialsForm);
  }, [clearErrors, media, MEDIA_LINKS, MEDIA_USERNAMES, setError]);

  const clearValue = useCallback((field: string) => {
    setValue(field as keyof CompanyCredentialsForm, '');
    clearErrors('social_networks'[field]);
    clearErrors(field as keyof CompanyCredentialsForm);
  }, [clearErrors]);

  return (
    <section className={clsx(s.media, s.section)}>
      <h2>{t(`userAccount:company.${isDesktop ? 'media' : 'social_media'}`)}</h2>
      <div className={sField.field}>
        <p className={sField.field_header}>{t('userAccount:company.links')}</p>
        <div className={clsx(sField.wrapper)}>
          {COMPANY_CONTACTS.map((el) => {
            const lowerEl = el.toLowerCase();
            return (
              <div className={styles.input_wrapper} key={el}>
                <InputText
                  fullWidth
                  defaultValue={media[lowerEl] || ''}
                  placeholder={`${t('userAccount:company.link_to')}${el === 'Twitter' ? `X(${el})` : el}`}
                  {...register(`${lowerEl}` as keyof CompanyCredentialsForm, {
                    onBlur: () => handleBlur(lowerEl),
                  })}
                />
                <SvgIcon
                  name={`${lowerEl}-no-bg-24`}
                  className={clsx(styles[lowerEl], styles.media_icon)}
                />
                {media[lowerEl] && watch(lowerEl as keyof CompanyCredentialsForm) && (
                  <SvgIcon
                    name="close-icon"
                    className={styles.close_icon}
                    onClick={() => clearValue(lowerEl)}
                  />
                )}
                {(errors[lowerEl] || errors.social_networks?.[lowerEl]) && (
                  <p className={sField.error}>
                    {(errors?.social_networks?.[lowerEl] as FieldError & { code: string })?.code
                      ? t(`forms:validationErrors.${(errors.social_networks?.[lowerEl] as FieldError & { code: string }).code}`)
                      : errors[lowerEl].message as string}
                  </p>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
