import { ComponentType } from 'react';
import type { NextPageContext } from 'next';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { ServerErrorPage } from '@/modules/app/components/error-pages/server-error-page.component';
import { rollbar } from './rollbar.config';

type NextComponentTypeWithGetInitialProps<P> = ComponentType<P> & {
  getInitialProps?: (ctx: NextPageContext) => Promise<Partial<P>>;
  getStaticProps?: (ctx: NextPageContext) => Promise<Partial<P>>;
  getServerSideProps?: (ctx: NextPageContext) => Promise<Partial<P>>;
};


export const withRollbarProvider = <P extends object>(
  WrappedComponent: NextComponentTypeWithGetInitialProps<P>,
) => {
  const WithRollbar = (props: P) => (rollbar.options.enabled ? (
    <Provider instance={rollbar}>
      <ErrorBoundary
        level="error"
        fallbackUI={ServerErrorPage}
      >
        <WrappedComponent {...props} />
      </ErrorBoundary>
    </Provider>
  ) : <WrappedComponent {...props} />);

  WithRollbar.displayName = `WithRollbar(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  const proxyMethod = (method?: (ctx: NextPageContext) => Promise<unknown>) => {
    if (method) {
      return async (ctx: NextPageContext) => await method(ctx) || {};
    }
    return {};
  };

  WithRollbar.getInitialProps = proxyMethod(WrappedComponent.getInitialProps);
  WithRollbar.getStaticProps = proxyMethod(WrappedComponent.getStaticProps);
  WithRollbar.getServerSideProps = proxyMethod(WrappedComponent.getServerSideProps);

  return WithRollbar;
};
