import axios, { AxiosError } from 'axios';
import { api } from '@/modules/shared/api';
import Cookie from 'js-cookie';
import { endpoints } from './endpoints';
import { RegistrationType } from '../types';
import { getTokenExpiration } from '../providers/auth.helpers';

type LoginData = {
  email?: string;
  phone_number?: string;
  password?: string;
};

export const authAxios = axios.create({
  baseURL: api.defaults.baseURL,
  paramsSerializer: api.defaults.paramsSerializer,
});

export const AuthApi = {
  refreshAccessToken: async (refreshToken: string, options = {}) => {
    const { data } = await api.post<{ access: string }>(
      endpoints.refreshAccessToken(),
      { refresh: refreshToken },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: '',
        },
        ...options,
      },
    );
    if (data.access) {
      const exp = getTokenExpiration(data.access);
      Cookie.set('access_token', data.access, { expires: exp });
    }

    return data.access;
  },

  googleLogin: async (data: { token: string }) => {
    const response = await authAxios.post<{ access: string, refresh: string, id: string }>(
      endpoints.googleLogin(),
      data,
    );

    return response.data;
  },

  telegramLogin: async (data: {
    id: number,
    first_name?: string,
    username?: string,
    photo_url?: string,
    auth_date: number,
    hash: string
  }) => {
    const response = await authAxios.post<{ access: string, refresh: string, id: string }>(
      endpoints.telegramLogin(),
      data,
    );

    return response.data;
  },

  resetPasswordRequest: async (data: { email: string } | { phone_number: string }) => {
    const response = await authAxios.post<{ status: 'success' }>(
      endpoints.resetPasswordRequest(),
      data,
    );

    return response.data;
  },

  resetPasswordComplete: async (data: {
    password: string,
    password2: string
  } & ({
    email: string,
    code: string,
  } | {
    phone_number: string,
    totp: string,
  })) => {
    const response = await authAxios.post<string>(
      endpoints.resetPasswordComplete(),
      data,
    );

    return response.data;
  },

  confirmRegistration: async (data: {
    totp: string,
    phone_number: string,
    registration_type: 'phone_number',
  }) => {
    const response = await authAxios.post<{ phone_number: string, totp: string }>(
      endpoints.confirmRegistration(),
      data,
    );

    return response.data;
  },

  checkTotp: async (data: { operation_type: 'reset_password', totp: string, phone_number: string }) => {
    const response = await authAxios.post<string>(
      endpoints.checkTotp(),
      data,
    );

    return response.data;
  },

  sendTotp: async (data: { phone_number: string, operation_type: 'register' | 'reset_password' }) => {
    try {
      const response = await authAxios.post<{ status: string, message: string }>(
        endpoints.sendTotp(),
        data,
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response?.data) {
        throw ({ ...error.response.data });
      } else {
        throw {
          code: 'UNDEFINED_ERROR',
        };
      }
    }
  },

  login: async (loginData: LoginData) => {
    const { data } = await authAxios.post<{ access: string, refresh: string, id: string }>(
      endpoints.login(),
      loginData,
    );
    return data;
  },

  completeRegistration: async (
    { registrationType, ...body }: {
      registrationType: RegistrationType,
      totp?: string,
      token?: string,
      phone_number?: string,
      full_name?: string,
      password?: string,
      locale?: string,
    },
  ) => {
    const { data } = await authAxios.put(
      endpoints.completeRegistration(),
      {
        registration_type: registrationType,
        ...body,
      },
    );
    return data;
  },

  resendActivationLink: async (email: string) => {
    await authAxios.post(
      endpoints.resendActivationLink(),
      { email },
    );
  },
};
