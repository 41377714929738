import React from 'react';
import { User } from '@/modules/users';
import { UserCard } from '../../user-card';
import { useUserCompanyById } from '../../../../company/queries';


interface Props {
  userData: User;
  adId: number;
  adUserId: number;
}

export const ContactsBlock = (props: Props) => {
  const { userData, adId, adUserId } = props;
  const { data: companyData } = useUserCompanyById(userData?.company_id);

  return (
    <UserCard
      card="adUser"
      adId={adId}
      adUserId={adUserId}
      userData={userData}
      companyData={companyData}
    />
  );
};
