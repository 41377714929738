import { useParams } from 'next/navigation';
import Cookies from 'js-cookie';
import {
  useCallback,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';
import { api } from '@/modules/shared/api';
import { AuthApi } from '../api';
import { getTokenExpiration, cleanCookies } from './auth.helpers';

export const useLogout = () => {
  const params = useParams();

  return useCallback(async () => {
    try {
      await api.post('/api/users/logout/');
      cleanCookies();
      window.location.href = `/${params?.locale || ''}`;
    } catch (e) {
      console.error(e);
    }
  }, [params?.locale]);
};


export const useWatchAuthTokens = (
  accessToken: string,
  setAccessToken: Dispatch<SetStateAction<string | undefined>>,
  refreshToken: string,
  setRefreshToken: Dispatch<SetStateAction<string | undefined>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  lastLoginTime: number | null,
) => {
  const timeoutRef = useRef<number | undefined>();
  const previousTokenRef = useRef(accessToken);
  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchAccessToken = useCallback(async () => {
    if (abortControllerRef.current?.signal.aborted) return;
    previousTokenRef.current = accessToken;

    try {
      const controller = new AbortController();
      abortControllerRef.current = controller;

      setIsLoading(true);
      const token = await AuthApi.refreshAccessToken(refreshToken, {
        signal: controller.signal,
      });
      const exp = getTokenExpiration(token);

      if (previousTokenRef.current !== token) {
        Cookies.set('access_token', token, { expires: exp });
        setAccessToken(token);
        previousTokenRef.current = token;
      }
    } catch (err) {
      if (abortControllerRef.current?.signal.aborted) return;
      if (err.response?.status === 401) {
        setAccessToken(undefined);
        setRefreshToken(undefined);
        cleanCookies();
        window.location.reload();
      }
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, [accessToken, refreshToken]);

  useEffect(() => {
    if (accessToken === previousTokenRef.current || !refreshToken) return;

    const isTokenRecentlySet = lastLoginTime && Date.now() - lastLoginTime < 10000;
    if (isTokenRecentlySet) return; // при первом логине не должен сразу же перезапрашиваться токен

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const tokenExpiresIn = +(getTokenExpiration(accessToken) || new Date()) - Date.now() - 30000;

    timeoutRef.current = setTimeout(
      fetchAccessToken,
      Math.max(tokenExpiresIn, 0),
    ) as unknown as number;

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      abortControllerRef.current?.abort();
    };
  }, [accessToken, refreshToken, fetchAccessToken]);
};
