import React, { memo } from 'react';
import { CreationLocation } from '@/modules/locations';
import { CreationContacts } from '@/modules/shared/components/creation-contacts';
import { UserTypeEnum } from '@/modules/users';
import { useMeContext } from '@/modules/me/providers';
import { useCreateAdActionsContext, useCreateAdContext } from '../../context';
import { CATEGORIES_WITHOUT_DISTRICT } from '../../const';

export const ContactInformation = memo(() => {
  const { me } = useMeContext();
  const type = me?.company_id ? UserTypeEnum.Company : UserTypeEnum.Individual;
  const { setIsPhoneVerified } = useCreateAdActionsContext();
  const { category } = useCreateAdContext();

  const districtRequired = !CATEGORIES_WITHOUT_DISTRICT.includes(category?.codename || '');

  return (
    <>
      <CreationLocation user_type={type} districtRequired={districtRequired} />
      <CreationContacts setIsAdPhoneVerified={setIsPhoneVerified} />
    </>
  );
});
