import React from 'react';
import Loadingmain from '@/components/shared/skeleton/loadingmain';
import { useMeContext } from '@/modules/me';
import { useUserType } from '@/modules/users/hooks';
import { UserInfoMobile, LinksBlock } from './components';
import styles from './personal-account-mobile.module.scss';

export const PersonalAccountMobile = () => {
  const { me: userProfile, isLoading } = useMeContext();
  const { type, companyData } = useUserType(
    userProfile?.user_type,
    userProfile?.company_id,
  );

  if (isLoading || !userProfile) {
    return <Loadingmain />;
  }

  return (
    <section className={styles.personal_account}>
      <UserInfoMobile user={userProfile} type={type} companyData={companyData} />
      <LinksBlock user={userProfile} status={companyData?.status} type={type} />
    </section>
  );
};
