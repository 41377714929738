import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { AdsBlock } from '@/modules/app/components';
import { IEvent } from '@/modules/events';
import { BannerImages } from '@/modules/banners';
import useTranslation from 'next-translate/useTranslation';
import { getMainOpenGraph, Seo } from '@/modules/seo';
import { BannerAndCategoryBlock } from '../app/components/index-page/banner-and-category-block';
import styles from './index-page.module.scss';

interface Props {
  events: IEvent[] | [];
  bannerList: BannerImages[] | [];
}

export function IndexPage({ events, bannerList }: Props) {
  const { t, lang } = useTranslation();
  const { locale } = useRouter();
  const description = t('seo:mainPage.description');

  const mainTitle = {
    first: t('index-page:adsBlock.firstSeoTitle'),
    second: t('index-page:adsBlock.secondSeoTitle'),
    third: t('index-page:adsBlock.thirdSeoTitle'),
  };

  useEffect(() => {
    if (lang === 'default' && locale !== 'default' && typeof window !== 'undefined') {
      window.location.reload();
    }
  }, [lang, locale]);

  const openGraph = getMainOpenGraph(t('seo:defaultTitle'), description, lang);

  return (
    <>
      <Seo description={description} openGraph={openGraph} />
      <main className="main_container">
        <div className={styles.info_banner}>
          <p>
            <b>{t('index-page:infoBanner.bold')}</b>
            {t('index-page:infoBanner.regular')}
          </p>
        </div>
        <BannerAndCategoryBlock events={events} bannerList={bannerList} />
        <AdsBlock />
        <h1 className={styles.main_title}>
          <span className={styles.title}>
            {mainTitle.first}
            <span className={styles.number}> №1 </span>
          </span>
          <span className={styles.subtitle}>{mainTitle.second}</span>
          <span className={clsx(styles.second_subtitle, { [styles.second_subtitle_ru]: lang === 'ru' })}>
            <span className={styles.two_thousand}> 2000+ </span>
            <span className={clsx({ [styles.text]: lang === 'ru' })}>{mainTitle.third}</span>
          </span>
        </h1>
        <div className={styles['seo-links']}>
          Bazaar - Первая платформа для покупок и продаж в Таиланде
          На Bazaar вы найдете удобный и быстрый способ купить или продать практически все, что угодно. От
          {' '}
          <a href="/ru/allcities/catalog/at-home" target="_blank">товаров для дома</a>
          {' '}
          до
          {' '}
          <a target="_blank" href="/ru/allcities/catalog/at-home">автомобилей</a>
          , от
          {' '}
          <a target="_blank" href="/ru/allcities/catalog/personal">одежды</a>
          {' '}
          до
          {' '}
          <a href="/ru/allcities/catalog/property" target="_blank">недвижимости</a>
          {' '}
          в Таиланде. Если вы ищете что-то, скорее всего, оно есть на Bazaar. Мы предлагаем площадку, где каждый пользователь может найти что-то для себя и иметь возможность предоставить свои
          {' '}
          <a target="_blank" href="/ru/allcities/catalog/services">услуги</a>
          {' '}
          или продать ненужные вещи.
          Если у вас есть что-то, что вы хотели бы продать, Bazaar Origin сделает этот процесс максимально простым. Зарегистрируйтесь в личном кабинете,
          {' '}
          <a target="_blank" href="/ru/product/create">создайте объявление</a>
          , добавьте фотографии и описание, установите цену, и ваш товар будет доступен тысячам потенциальных покупателей по всему Таиланду.
          Вы сможете купить или продать что угодно на
          {' '}
          <a target="_blank" href="/ru/phuket/catalog/all">Пхукете</a>
          ,
          {' '}
          <a target="_blank" href="/ru/pangan/catalog/all">Пангане</a>
          ,
          {' '}
          <a target="_blank" href="/ru/samui/catalog/all">Самуи</a>
          ,
          {' '}
          <a target="_blank" href="/ru/pattaya/catalog/all">Паттайе</a>
          ,
          {' '}
          <a target="_blank" href="/ru/krabi/catalog/all">Краби</a>
          {' '}
          и других городах Таиланда. Платформа работает на нескольких языках (на
          {' '}
          <a target="_blank" href="/ru">русском</a>
          ,
          {' '}
          <a target="_blank" href="/en">английском</a>
          ).
          Ищете
          {' '}
          <a target="_blank" href="/ru/allcities/catalog/job">работу в Таиланде</a>
          ? Bazaar поможет вам найти вакансии. Ищите сотрудника? На Bazaar вы найдете резюме.
          На доске объявлений Bazaar представлены товары и услуги в широком спектре категорий, что позволяет пользователям найти почти все, что им может понадобиться. От
          {' '}
          <a target="_blank" href="/ru/allcities/catalog/spare-parts">запчастей и аксессуаров для автомобиля</a>
          {' '}
          до
          {' '}
          <a target="_blank" href="/ru/allcities/catalog/business">оборудования для бизнеса</a>
          , от
          {' '}
          <a target="_blank" href="/ru/allcities/catalog/electronics">электроники</a>
          {' '}
          до
          {' '}
          <a target="_blank" href="/ru/allcities/catalog/animals">домашних животных</a>
          {' '}
          – все это доступно на Bazaar.
          Кроме купли-продажи, на Bazaar вы также можете узнать о предстоящих событиях в вашем регионе. Раздел
          {' '}
          <a target="_blank" href="/ru/posters">&quot;Афиша&quot;</a>
          {' '}
          предлагает актуальную информацию о мероприятиях, концертах и фестивалях, мастер-классах позволяя вам всегда быть в курсе интересных событий в Таиланде.
          Присоединяйтесь к сообществу Bazaar Origin сегодня и откройте для себя простой и удобный способ взаимодействия с рынком Таиланда. Независимо от того, хотите ли вы что-то продать или находитесь в поиске, Bazaar предоставляет вам инструменты для успешной сделки.

        </div>
      </main>
      {/* {showCookieModal && <CookieModal />} */}
    </>
  );
}
