import React, {
  useCallback, useEffect, useLayoutEffect, useMemo, useState,
} from 'react';
import Cookie from 'js-cookie';
import { useResetCookieOnce } from '@/modules/shared/hooks';
import { useIsAuthModalOpen } from '@/modules/auth/hooks';
import { AuthModal } from '../components';
import { useLogout, useWatchAuthTokens } from './auth.hooks';
import { useLoginMutation } from './auth.mutations';
import { AuthContext, IAuthContext } from '../contexts';

type FormValue = string | number;

export function AuthProvider({ children, accessToken: initialAccessToken }) {
  const [accessToken, setAccessToken] = useState(() => initialAccessToken || Cookie.get('access_token') || '');
  const [refreshToken, setRefreshToken] = useState(() => Cookie.get('refresh_token') || '');
  const [isLoading, setIsLoading] = useState(false);
  const [draft, setDraft] = useState<Record<string, FormValue> | null>(null);
  const [lastLoginTime, setLastLoginTime] = useState<number | null>(null);
  useWatchAuthTokens(
    accessToken,
    setAccessToken,
    refreshToken,
    setRefreshToken,
    setIsLoading,
    lastLoginTime,
  );

  const handleLoginSuccess = useCallback(() => {
    setLastLoginTime(Date.now());
  }, []);

  const logout = useLogout();
  const {
    login,
    isLoading: isLoggingIn,
    error: loginError,
    resetLoginError,
  } = useLoginMutation(
    setRefreshToken,
    setAccessToken,
    handleLoginSuccess,
  );

  const isModalOpen = useIsAuthModalOpen();
  const value = useMemo<IAuthContext>(
    () => ({
      isLoading: isLoading || isLoggingIn,
      isAuthenticated: !!accessToken,
      login,
      logout,
      loginError: loginError as Error | undefined,
      draft,
      setDraft,
      resetLoginError,
      isModalOpen,
      setRefreshToken,
      setAccessToken,
    }),
    [isLoading, accessToken, logout, isLoggingIn, isLoading, login, loginError, draft, resetLoginError, isModalOpen],
  );

  const { resetCookie } = useResetCookieOnce();

  useLayoutEffect(() => {
    const reset = () => {
      setAccessToken('');
      setRefreshToken('');
    };
    resetCookie(reset);
  }, [resetCookie]);

  useEffect(() => {
    if (accessToken) {
      const resetPassword = Cookie.get('resetPassword');
      const resetPassword2 = Cookie.get('resetPassword2');
      const forgotPasswordEmail = Cookie.get('forgotPasswordEmail');

      if (resetPassword || resetPassword2 || forgotPasswordEmail) {
        Cookie.remove('resetPassword');
        Cookie.remove('resetPassword2');
        Cookie.remove('forgotPasswordEmail');
      }
    }
  }, [accessToken]);

  return (
    <AuthContext.Provider value={value}>
      <>
        <AuthModal />
        {children}
      </>
    </AuthContext.Provider>
  );
}
