import React, { useCallback, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { IAd } from '@/modules/ads/types';
import { IEvent } from '@/modules/events';
import { ButtonSwitcher } from '@/modules/shared/components';
import { FavouriteEvents } from './favourite-events.component';
import { FavouriteAds } from './favourite-ads.component';
import { getInitialSwitchButtonValue } from '../helpers';
import styles from './have-anything.module.scss';

interface Props {
  events: IEvent[] | [];
  ads: IAd[] | [];
}

export const HaveAnything = ({ ads = [], events = [] }: Props) => {
  const { t } = useTranslation('favourites');
  const initialValue = getInitialSwitchButtonValue();
  const [value, setValue] = useState(initialValue || 'announcements');

  const buttons = [
    { value: 'announcements', name: `${t('switchButton.announcements')} ${ads?.length}` },
    { value: 'events', name: `${t('switchButton.events')} ${events?.length}` },
  ];
  const handleChange = useCallback((button: string) => {
    setValue(button);
    localStorage.setItem('favourites_switch_value', button);
  }, []);

  return (
    <section className={styles.have_anything}>
      <div className={styles.button_wrapper}>
        <ButtonSwitcher
          buttons={buttons}
          onChange={handleChange}
          value={value}
          kind="outline"
        />
      </div>
      {value === 'announcements' && <FavouriteAds ads={ads} />}
      {value === 'events' && <FavouriteEvents events={events} />}
    </section>
  );
};
