import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { Button } from '@/modules/shared/components';
import { profileBasePath } from '@/modules/shared/const';
import type { ShareProfileModeType } from '../../../types';
import { ShareLinkButton } from '../share-link-button';
import styles from './action-buttons.module.scss';

interface ActionButtonsProps {
  mode: ShareProfileModeType;
  id: string | number;
  onClose: () => void;
}

export const ActionButtons = ({ mode, id, onClose }: ActionButtonsProps) => {
  const { t } = useTranslation('');
  const isCurrentPageAccount = window.location.href.includes(`/${profileBasePath}/account`);

  if (mode === 'view') {
    return (
      <Button
        buttonSize="s"
        buttonColor="green"
        appearance="primary"
        className={styles.profile_bazaar}
        fullWidth
        onClick={onClose}
      >
        {t('common:shareProfile.profileOnBazaar')}
      </Button>
    );
  }

  return (
    <div className={styles.container}>
      <Button
        buttonSize="s"
        buttonColor="green"
        appearance="primary"
        className={clsx(styles.button, styles.button_white)}
        fullWidth
        as="link"
        href={`/companies/${id}`}
      >
        {t('common:shareProfile.goToProfile')}
      </Button>

      <ShareLinkButton href={`qr/companies/${id}`} />

      <Button
        buttonSize="s"
        buttonColor="grey"
        appearance="primary"
        className={clsx(styles.button, styles.button_dark)}
        fullWidth
        {...(!isCurrentPageAccount && { as: 'link', href: `/${profileBasePath}/account` })}
        {...(isCurrentPageAccount && { onClick: onClose })}
      >
        {t('common:shareProfile.editData')}
      </Button>
    </div>
  );
};
