import React, {
  useEffect, useCallback, useMemo, useState,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import { AdCard } from '@/modules/app/components/index-page/ad-card';
import { getDeclension } from '@/modules/shared/helpers';
import { Pagination, Sorting } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { IAd } from '@/modules/ads/types';
import clsx from 'clsx';
import { adsSortingHelper } from '../helpers';
import styles from './favourite-ads.module.scss';

const all = 'all'; // для мобильной сортировки

export const FavouriteAds = ({ ads }: { ads: IAd[] }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const { isDesktop } = useMatchMedia();

  const [sortMobileValue, setSortMobileValue] = useState(all); // мобильная сортировка
  const [sortValue, setSortValue] = useState('newest'); // десктопная сортировка
  const options = useMemo(
    () => ['newest', 'oldest', 'decreasing', 'increasing'].map(
      (el) => ({ value: el, label: t(`adv:filters.sortingModal.${el}`) }),
    ),
    [t],
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [sortMobileValue, sortValue]);

  const adsCount = getDeclension({
    count: ads.length,
    t,
    singular: 'favourites:favouriteAds.one',
    few: 'favourites:favouriteAds.few',
    many: 'favourites:favouriteAds.many',
  });

  const existingСategories = useMemo(() => {
    const categoriesSet = ads.reduce((acc, ad) => {
      acc.add(ad.category.codename);
      return acc;
    }, new Set<string>());

    return [all, ...Array.from(categoriesSet)];
  }, [ads]); // для тегов сортировки в мобильном разрешении

  const handleMobileChange = (event: React.ChangeEvent<HTMLInputElement>) => setSortMobileValue(event.target.value);

  const quantityAdsInCategory = useCallback((category: string) => {
    if (category === all) return ads.length;
    return ads.filter((ad) => ad.category.codename === category).length;
  }, [ads]);

  const filteringAds = useMemo(() => {
    if (isDesktop) {
      return adsSortingHelper(ads, sortValue);
    }
    return ads.filter((ad) => sortMobileValue === all || ad.category.codename === sortMobileValue);
  }, [ads, sortValue, sortMobileValue, isDesktop]);

  return (
    <div className={styles.favourite_ads}>
      {!ads.length ? (
        <h3 className={styles.title_no_ads}>{t('favourites:favouriteAds.none')}</h3>
      ) : (
        <div className={styles.favourite_ads_wrapper}>
          {isDesktop ? (
            <div className={styles.favourite_ads_header}>
              <h3>{adsCount}</h3>
              <Sorting
                value={sortValue}
                setValue={setSortValue}
                options={options}
                rightPosition
              />
            </div>
          ) : (
            <div className={styles.favourite_ads_header}>
              {existingСategories.map((category) => (
                <label
                  key={category}
                  className={clsx(styles.label, {
                    [styles.label_active]: category === sortMobileValue,
                  })}
                >
                  <input
                    type="checkbox"
                    value={category}
                    onChange={handleMobileChange}
                    checked={sortMobileValue === category}
                  />
                  {`${t(`favourites:adsCategories.${category}`)} (${quantityAdsInCategory(category)})`}
                </label>
              ))}
            </div>
          )}
          <div className={styles.ads}>
            <Pagination
              itemsPerPage={10}
              currentPage={currentPage}
              fullList={!isDesktop || ads.length < 10}
              setCurrentPage={(page) => setCurrentPage(page)}
              totalPages={Math.ceil(filteringAds.length / 10)}
              className={styles.favourite_pagination_wrapper}
            >
              {filteringAds.map((ad) => (
                <AdCard key={ad.id} extendedCard={isDesktop} ad={ad} />
              ))}
            </Pagination>
          </div>
        </div>
      )}
    </div>
  );
};
