import React, { memo } from 'react';
import { SvgIcon } from '@/modules/shared/components';
import { compose } from '@/modules/shared/helpers';
import { FilterQuery, useFilterFormContext } from '@/modules/shared/hooks';

import { withDynamicFilters } from '../hoc/withDynamicFilters';
import { useTranslateFilterTags } from '../hooks/use-translate-filter-tags';

import styles from './filter-tags.module.scss';

interface FilterValuesProps {
  handleRemoveFilter: (name: FilterQuery) => void;
}

const FilterValues = ({ handleRemoveFilter }: FilterValuesProps) => {
  const form = useFilterFormContext();
  const translatedTags = useTranslateFilterTags(form.value);

  return (
    <div className={styles.filter_tags_list}>
      {translatedTags.map(({ title, codename, value }) => (
        <button
          key={codename}
          type="button"
          className={styles.filter_tag}
        >
          <p className={styles.filter_title}>
            {title}
            :
          </p>
          <p className={styles.filter_value}>
            {String(value)}
            {codename === 'min_price' || codename === 'max_price'
              ? <SvgIcon name="THB-12-medium" />
              : null}
          </p>
          <SvgIcon name="close" onClick={() => handleRemoveFilter(codename as FilterQuery)} />
        </button>
      ))}
    </div>
  );
};

export const FilterTags = compose(
  memo,
  withDynamicFilters,
)(FilterValues);
