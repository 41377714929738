import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { useSwipeable } from 'react-swipeable';

import { useMatchMedia } from '../../hooks/use-match-media.hook';
import { scrollElementIntoViewCenter } from '../../helpers';
import { ItemLoader } from '../loaders';
import { SvgIcon } from '../svg-icon';

import { GalleryModal } from '../gallery-modal/gallery-modal.component';
import styles from './gallery-block.module.scss';

export type GalleryBlockProps = {
  images: {
    image: string,
    thumbnail?: string,
  }[];
};

const Loader = () => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
    <ItemLoader isLoading size={60} />
  </Box>
);

export const GalleryBlock = ({ images }: GalleryBlockProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [preloading, setPreloading] = useState(false);
  const { isMobile } = useMatchMedia();
  const navigationRefs = useRef<Record<number, HTMLDivElement | null>>({});

  const handlePrev = useCallback(() => {
    if (!images.length || images.length === 1) return;
    setPreloading(true);
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  }, [images.length]);

  const handleNext = useCallback(() => {
    if (!images.length || images.length === 1) return;
    setPreloading(true);
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  }, [images.length]);

  useEffect(() => {
    scrollElementIntoViewCenter(navigationRefs.current[currentIndex]);
  }, [currentIndex]);

  const handleScrollNavigate = (position: number) => {
    const targetIndex = currentIndex + position;

    if (targetIndex <= 0) {
      setCurrentIndex(0);
    } else if (targetIndex >= images.length - 1) {
      setCurrentIndex(images.length - 1);
    } else {
      setCurrentIndex(targetIndex);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
  });

  const showArrow = images.length > 1;
  const showNavigateArrow = images.length > 5;

  if (!images[currentIndex]) {
    return (
      <img
        src="/assets/images/fallback-background.png"
        alt="There are no photos"
        style={{
          width: '100%', height: '100%', maxWidth: '753px', maxHeight: '470px',
        }}
      />
    );
  }

  return (
    <div className={clsx(styles.wrapper, {
      [styles.wrapper_hideArrow]: !showArrow,
      [styles.wrapper_hideNavigateArrow]: !showNavigateArrow,
    })}
    >
      <div
        className={styles.images}
        {...swipeHandlers}
      >
        <div
          className={styles.images__backBlur}
          style={{
            backgroundImage: `url(${images[currentIndex].thumbnail || images[currentIndex].image})`,
          }}
        />
        <div className={styles.images__counter}>{isMobile ? `${currentIndex + 1} из ${images.length}` : ''}</div>
        <button className={styles.prev} onClick={handlePrev}>
          <SvgIcon name="arrow-forward-left" />
        </button>
        {preloading && <Loader />}
        <img
          onClick={() => {
            setOpenModal(true);
          }}
          className={styles.images__item}
          loading="lazy"
          onLoad={() => setPreloading(false)}
          src={images[currentIndex].image}
          alt="gallery"
        />
        <button className={styles.next} onClick={handleNext}>
          <SvgIcon name="arrow-forward-right" />
        </button>
      </div>
      {!isMobile && (
        <div className={styles.footer}>
          <button className={styles.footer__navAction} onClick={() => handleScrollNavigate(-5)}>
            <SvgIcon name="arrow-forward-left" />
          </button>
          <div className={styles.navigate}>
            {images.map((src, index) => (
              <div
                key={index}
                ref={(element) => {
                  navigationRefs.current[index] = element;
                }}
                onClick={() => setCurrentIndex(index)}
                className={clsx(styles.navigate__item, {
                  [styles.navigate__item_active]: currentIndex === index,
                })}
                style={{
                  backgroundImage: `url(${src.thumbnail || src.image})`,
                }}
              />
            ))}
          </div>
          <button className={styles.footer__navAction} onClick={() => handleScrollNavigate(5)}>
            <SvgIcon name="arrow-forward-right" />
          </button>
        </div>
      )}
      <GalleryModal
        currentImageIndex={currentIndex}
        setCurrentImageIndex={setCurrentIndex}
        open={openModal}
        onClose={() => setOpenModal(false)}
        images={images}
        setStateKeyboard={setPreloading}
        onScrollNavigate={handleScrollNavigate}
      />
    </div>
  );
};
