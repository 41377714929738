import React, {
  FC, RefObject, useCallback, useMemo,
} from 'react';
import { SvgIcon } from '@/modules/shared/components';
import { stopEvent } from '@/modules/shared/helpers';
import useTranslation from 'next-translate/useTranslation';
import styles from './search-suggestion.module.scss';

interface SearchProps {
  isSearching?: boolean;
  history: string[];
  suggestions?: string[];
  onSearch: (value: string) => void;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  inputRef: RefObject<HTMLInputElement>;
  isInputFocused: boolean;
  setIsInputFocused: React.Dispatch<React.SetStateAction<boolean>>;
  clearHistory: () => void;
}

const List = ({
  items,
  iconName,
  onItemClick,
}: {
  items: string[];
  iconName: string;
  onItemClick: (item: string) => void;
}) => (
  <ul className={styles.history_list}>
    {items.map((item, index) => (
      <li
        key={index}
        onClick={() => onItemClick(item)}
      >
        <SvgIcon name={iconName} style={{ fontSize: '24px' }} />
        {item}
      </li>
    ))}
  </ul>
);

export const SearchSuggestion: FC<SearchProps> = ({
  isSearching,
  history,
  onSearch,
  setSearch,
  inputRef,
  isInputFocused,
  setIsInputFocused,
  clearHistory,
  suggestions,
}) => {
  const { t } = useTranslation('common');

  const handleSuggestionClick = useCallback((item: string) => {
    setSearch(item);
    setIsInputFocused(false);
    inputRef.current?.blur();
    onSearch(item);
  }, [inputRef, onSearch, setIsInputFocused, setSearch]);

  const recentHistory = useMemo(() => [...history].slice(0, 5), [history]);

  return (
    <>
      {!isSearching && isInputFocused && recentHistory.length > 0 && (
        <div className={styles.history} onMouseDown={stopEvent}>
          <div className={styles.history_headline}>
            <p className={styles.title}>{t('searchBlock.searched')}</p>
            <span
              tabIndex={0}
              role="button"
              onClick={clearHistory}
              className={styles.clear_history}
              onKeyDown={(e) => { if (e.key === 'Enter') clearHistory(); }}
            >
              {t('clear')}
            </span>
          </div>
          <List
            iconName="clock"
            items={recentHistory}
            onItemClick={handleSuggestionClick}
          />
        </div>
      )}

      {isSearching && isInputFocused && suggestions && suggestions?.length > 0 && (
        <div className={styles.history} onMouseDown={stopEvent}>
          <List
            iconName="search"
            items={suggestions}
            onItemClick={handleSuggestionClick}
          />
        </div>
      )}
    </>
  );
};
