import React, { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import useTranslation from 'next-translate/useTranslation';
import Cookies from 'js-cookie';
import getConfig from 'next/config';

import { SvgIcon } from '@/modules/shared/components';

import { isWebViewApp } from '@/modules/shared/helpers';
import { getTokenExpiration } from '../../../../providers/auth.helpers';
import { useAuthContext } from '../../../../contexts';
import { AuthApi } from '../../../../api';


const { publicRuntimeConfig: { telegramOAuthBotId } } = getConfig();


export const TelegramOauth = () => {
  const router = useRouter();
  const { locale } = router;
  const { setAccessToken, setRefreshToken } = useAuthContext();
  const { t } = useTranslation();

  const popupRef = useRef<null | Window>(null);

  const openTelegramAuth = () => {
    const authUrl = `https://oauth.telegram.org/auth?bot_id=${telegramOAuthBotId}&origin=${window.location.origin}&lang=${locale}`;
    const width = 550;
    const height = 470;
    /**
     * https://telegram.org/js/widget-frame.js?62
     * Определение размеров взял из кода телеги
     */
    // @ts-expect-error https://telegram.org/js/widget-frame.js?62
    const left = Math.max(0, (window.screen.width - width) / 2) + (window.screen.availLeft || 0);
    // @ts-expect-error https://telegram.org/js/widget-frame.js?62
    const top = Math.max(0, (window.screen.height - height) / 2) + (window.screen.availTop || 0);

    const popup = window.open(authUrl, 'telegram_oauth', `width=${width},height=${height},top=${top},left=${left},status=0,location=0,menubar=0,toolbar=0`);
    const openSameWindow = popupRef.current === popup;
    popupRef.current = popup;

    const postMessage = (data) => {
      if (!popupRef.current || !popupRef.current.window) return;

      popupRef.current.window.postMessage(JSON.stringify(data), '*');
    };

    if (!popupRef.current) return;

    const focus = () => {
      if (!popupRef.current) return;
      postMessage({
        event: 'focus',
        has_focus: document.hasFocus(),
      });
    };
    const blur = () => {
      if (!popupRef.current) return;
      postMessage({
        event: 'focus',
        has_focus: document.hasFocus(),
      });
    };

    const checkClose = () => {
      if (!popupRef.current?.window || popupRef.current.window.closed) {
        popupRef.current = null;
        window.removeEventListener('focus', focus);
        window.removeEventListener('blur', blur);
        return;
      }
      setTimeout(checkClose, 100);
    };

    if (!openSameWindow) {
      window.addEventListener('focus', focus);
      window.addEventListener('blur', blur);
    }

    if (!openSameWindow) {
      checkClose();
    }

    popupRef.current.focus();
  };

  useEffect(() => {
    const loginByResult = async (result) => {
      try {
        const response = await AuthApi.telegramLogin(result);

        Cookies.set('access_token', response.access, { expires: getTokenExpiration(response.access) });
        Cookies.set('refresh_token', response.refresh, { expires: getTokenExpiration(response.refresh) });
        Cookies.set('user_id', response.id, { expires: getTokenExpiration(response.access) });

        setRefreshToken(response.refresh);
        setAccessToken(response.access);
        toast.success(t('auth:success'));
      } catch (e) {
        toast.error(t('auth:error'));
      }
    };
    const onMessage = async (event: MessageEvent) => {
      if (event.origin !== 'https://oauth.telegram.org') return;

      const message = JSON.parse(event.data);
      if (message.origin !== window.location.origin || message.event !== 'auth_result') return;

      loginByResult(message.result);
    };
    const omMessageFromWebView = async (event: MessageEvent) => {
      if (!isWebViewApp()) return;

      try {
        const message = JSON.parse(event.data);
        if (message.event !== 'auth_result' || message.from !== 'webview-app') return;

        loginByResult(message.result);
      } catch (e) { /** */ }
    };

    window.addEventListener('message', onMessage);
    window.addEventListener('message', omMessageFromWebView);
    return () => {
      if (popupRef.current) {
        popupRef.current.close();
      }
      window.removeEventListener('message', onMessage);
      window.removeEventListener('message', omMessageFromWebView);
    };
  }, [t]);

  return (
    <button type="button" onClick={openTelegramAuth}>
      <SvgIcon
        style={{
          fontSize: '44px',
        }}
        name="telegram"
      />
      <script
        async
        src="https://telegram.org/js/telegram-widget.js?22"
        data-telegram-login="bazaar_dev_bot"
        data-size="medium"
        data-auth-url="https://asddsaads.ru"
        data-request-access="write"
      />
    </button>
  );
};
