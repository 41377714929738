// eslint-disable-next-line  import/newline-after-import
import { translateDistrict, translateCity } from '@/lib/translateDistrict';
export { onNetworkError } from './on-network-error.helper';
export { stopEvent } from './stop-event.helper';
export { useConvertHeicToPng } from './use-convert-heic-to-png.helper';
export { generateThumbnail } from './generate-thumbnail';
export { formatPrice } from './format-price.helper';
export { cleanPriceValue } from './clean-price-value.helper';
export { validateInputKeyPress, validateInputKeyPressFractional } from './validate-input-key-press.helper';
export { exampleFormatPhoneNumber, formatPhoneNumber } from './example-format-phone-number.helper';
export { isServerSide, isWebViewApp } from './environment';
export {
  getCurrentTimestamp,
  getNextEmailResendKey,
  getNextEmailResendTimestamp,
  getActivationEmail,
  setNextEmailResendTimestamp,
} from './current-timestamp.helpers';
export { cleanDescription } from './clean-description.helper';
export * from './locale';

export { validateInput, validateLinksInText, validateHtmlInText } from './validate-input-value.helper';
export { getDeclension } from './get-declension.helper';
export { getDateOfCreation } from './get-date-of-creation.helper';
export { useLogoClick } from './on-logo-click.helper';
export { formatDateTimeLongFormat } from './format-date-time-long.helper';
export { formatDateMonthYear } from './format-date-month-year.helper';
export { compressImage } from './compress-image.helper';
export * from './scroll-element-into-view';
export { isHeic } from './is-heic.helper';
export { getToday, getTodayWithTime } from './get-today.helper';
export { getMediaUsername, getWhatsAppLink } from './get-media-username.helper';
export { formatMessageCount } from './format-message-count.helpers';
export { compose } from './compose';
export const getCityAndDistrict = (locale: string, city?: string, district?: string) => {
  const translatedDistrict = translateDistrict(district, locale) || '';
  const translatedCity = translateCity(city, locale) || '';

  return `${translatedCity}${translatedDistrict ? ', ' : ''}${translatedDistrict}`;
};

export { checkExistedCityAndRedirect } from './check-existed-city-and-redirect.helper';
export { getServerSideCookieValue } from './get-server-side-cookie-value.helper';
export { formatNumber } from './format-number.helper';
export { getUserCompanyData } from './get-user-company-data.helper';
export { getTotalPages } from './get-total-pages.helper';
export { formatDateToISOString } from './format-date-to-iso-string.helper';
export { updateQueriesByKeys } from './update-queries-by-keys.helper';
export { getServerHost } from './get-server-host';

