import React, { useCallback, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Button, Toast, ShareButton } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import styles from './share-link-button.module.scss';

interface ShareLinkButtonProps {
  href: string;
  label?: string;
  labelToast?: string;
}

export const ShareLinkButton = ({
  href,
  label = 'common:shareProfile.shareLink',
  labelToast = 'common:shareProfile.linkCopied',
} : ShareLinkButtonProps) => {
  const { t } = useTranslation('');
  const { isDesktop } = useMatchMedia();
  const [isCopied, setIsCopied] = useState(false);

  const urlCopied = `${window.location.origin}/${href}`;

  const handleCopied = useCallback(() => {
    if (navigator) {
      navigator.clipboard.writeText(urlCopied)
        .then(() => setIsCopied(true))
        .catch((err) => console.error('Failed to copy: ', err));
    }
  }, []);


  return (
    <>
      {isDesktop && (
        <>
          <Button
            buttonSize="s"
            buttonColor="black"
            appearance="primary"
            className={styles.button}
            fullWidth
            onClick={handleCopied}
          >
            {t(label)}
          </Button>

          {isCopied && (
            <Toast
              label={t(labelToast)}
              iconName="share"
              onClose={() => setIsCopied(false)}
              className={styles.toast}
            />
          )}
        </>
      )}


      {!isDesktop && (
        <ShareButton
          title={t(label)}
          url={urlCopied}
          className={styles.button}
          option="secondary"
        />
      )}
    </>
  );
};
