import Cookie from 'js-cookie';
import { api, create, saveEntityImages } from '@/modules/shared/api';
import { EntityImage } from '@/modules/shared/types';
import { getMediaUsername, getWhatsAppLink } from '@/modules/shared/helpers';
import {
  INSTAGRAM_REGEX, TG_REGEX, TWITTER_REGEX, WA_REGEX, YOUTUBE_REGEX,
} from '@/modules/shared/const';
import { endpoints } from './endpoints';
import type {
  Company, CompanyLogo, CreateCompany, UpdateCompany,
} from '../types';
import { saveCompanyLogo } from '../helpers';

export const CompanyApi = {
  getMyCompany: async () => {
    const accessToken = Cookie.get('access_token');

    if (!accessToken) {
      throw new Error('Access token is missing');
    }
    const { data } = await api.get<Company>(endpoints.myCompany());
    return data;
  },

  getUserCompanyById: async (company: { id: number | string | null }) => {
    const { data } = await api.get<Company>(endpoints.userCompanyById(company));
    return data;
  },

  getUserCompanyByUrl: async (company: { url: string }) => {
    const { data } = await api.get<Company>(endpoints.userCompanyByUrl(company));
    return data;
  },

  createCompany: async (
    companyData: Omit<CreateCompany, 'logo_id'> & { user: number, logo?: File | null },
  ) => {
    const formData = new FormData();

    if (companyData.logo) {
      const logo = (await saveCompanyLogo(companyData.logo))[0];
      formData.append('logo_id', `${logo.id}`);
    }
    formData.append('user', `${companyData.user}`);
    formData.append('name', companyData.name);
    formData.append('description', companyData.description);
    formData.append('tax_id', companyData.tax_id.toString());
    // временно закомментировано
    // companyData.documents.forEach((el) => formData.append('documents', el));

    const { data } = await api.post(endpoints.myCompany(), formData);
    return data;
  },

  correctCompany: async (
    companyData: Partial<Omit<CreateCompany, 'logo_id'>> & { logo?: File | EntityImage | null },
  ) => {
    let tempLogo: File | EntityImage | null = null;
    if (companyData.logo) {
      if (companyData.logo instanceof File) {
        const [loadedLogo] = await saveCompanyLogo(companyData.logo);
        tempLogo = loadedLogo;
      } else {
        tempLogo = companyData.logo as EntityImage;
      }
    }

    const { data } = await api.patch(endpoints.myCompany(), {
      user: companyData.user,
      name: companyData.name,
      description: companyData.description,
      logo: tempLogo ?? {},
      tax_id: companyData.tax_id?.toString(),
    });
    return data;
  },

  updateCompany: async (companyData: UpdateCompany) => {
    let logo: CompanyLogo | null = null;
    if (companyData.logo) {
      const [loadedLogo] = await saveCompanyLogo(companyData.logo);
      logo = loadedLogo;
    }

    let banners: EntityImage[] | null = null;
    if (companyData.banners && companyData.banners.length) {
      banners = await saveEntityImages(
        companyData.banners,
        (formats) => create(endpoints.uploadBanners, { formats }),
      );
    }

    const { data } = await api.patch(endpoints.myCompany(), {
      user: companyData.user,
      name: companyData.name,
      description: companyData.description,
      logo: logo ?? {},
      custom_page_url: companyData.custom_page_url,
      social_networks: {
        whatsapp: companyData.whatsapp
          ? getWhatsAppLink(getMediaUsername(companyData.whatsapp.replace('+', ''), WA_REGEX))
          : null,
        instagram: companyData.instagram
          ? getMediaUsername(companyData.instagram, INSTAGRAM_REGEX)
          : null,
        youtube: companyData.youtube
          ? getMediaUsername(companyData.youtube, YOUTUBE_REGEX)
          : null,
        twitter: companyData.twitter
          ? getMediaUsername(companyData.twitter, TWITTER_REGEX)
          : null,
        telegram: companyData.telegram
          ? getMediaUsername(companyData.telegram, TG_REGEX)
          : null,
      },
      banners: banners || companyData.banners_to_remove
        ? [...(banners || []), ...(companyData.banners_to_remove || [])]
        : [],
    });

    return data;
  },

  deleteCompany: async () => {
    const { data } = await api.delete(endpoints.myCompany());
    return data;
  },
};
