import { GetServerSidePropsContext } from 'next';
import { IndexPage } from '@/modules/index-page';
import { pageFactory } from '@/modules/app/components';
import { BannersApi } from '@/modules/banners/api';
import { BannerImages } from '@/modules/banners';
import { EventsApi } from '@/modules/events/api';
import { IEvent } from '@/modules/events';
import { triggerEvent } from '@/modules/rollbar';

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const { req, res } = context;
  const { cookies } = req;
  const cityId: string = cookies.selectedCity || 'allcities';

  const filters = cityId !== 'allcities' ? { city: cityId } : '';

  if (cityId === 'allcities') {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1); // срок жизни 1 год

    res.setHeader(
      'Set-Cookie',
      `selectedCity=allcities; Path=/; Expires=${expires.toUTCString()}; Secure=${process.env.NODE_ENV !== 'development'}`,
    );
  }

  let events: IEvent[] = [];
  let bannerList: BannerImages[] = [];

  try {
    const [eventsData, bannersData] = await Promise.allSettled([
      EventsApi.list({
        page: 1,
        pageSize: 5,
        order: ['event_date', 'event_time'],
        filters,
      }),
      BannersApi.getBannersList(),
    ]);

    if (eventsData.status === 'fulfilled' && eventsData.value.results) {
      events = eventsData.value.results;
    }
    if (eventsData.status === 'rejected') {
      triggerEvent(new Error(eventsData.reason), 'Failed to fetch events');
    }

    if (bannersData.status === 'fulfilled') {
      bannerList = bannersData.value;
    } else {
      triggerEvent(new Error(bannersData.reason), 'Failed to fetch banners');
    }
  } catch (e) {
    console.error('Unexpected error in index-page getServerSideProps:', e);
    triggerEvent(e, 'Unexpected error in index-page getServerSideProps');
  }

  return {
    props: {
      events,
      bannerList,
    },
  };
}

export default pageFactory<{ events: IEvent[] | [], bannerList: BannerImages[] | [] }>(IndexPage);
