import Cookie from 'js-cookie';
import { api } from '@/modules/shared/api';
import { endpoints } from './endpoints';

export const adFavoritesApi = {
  getFavoriteAd: async () => {
    const accessToken = Cookie.get('access_token');

    if (!accessToken) {
      throw new Error('Access token is missing');
    }

    const { data } = await api.get(endpoints.getFavoriteAd());
    return data;
  },

  addFavoriteAd: async (id: number) => {
    const { data } = await api.post(endpoints.addFavoriteAd(id));
    return data;
  },

  deleteFavoriteAd: async (id: number) => {
    const { data } = await api.delete(endpoints.deleteFavoriteAd(id));
    return data;
  },
};
