import React, { useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { type SocialNetworks } from '@/modules/company/types';
import { useMatchMedia } from '@/modules/shared/hooks';

import type { ShareProfileModeType } from '../../../types';
import { SocialMediaItemView } from './social-media-item-view.component';
import { SocialMediaItemEdit } from './social-media-item-edit.component';
import styles from './social-media.module.scss';

interface SocialMediaProps {
  links: SocialNetworks;
  mode: ShareProfileModeType;
  onClose: () => void;
}

export const SocialMedia = ({ links, mode, onClose }: SocialMediaProps) => {
  const { t } = useTranslation('');
  const { isMobile, isTablet, isDesktop } = useMatchMedia();

  const socialLinks = useMemo(() => [
    { platform: 'instagram', url: links?.instagram ? `https://www.instagram.com/${links.instagram}` : '', username: links?.instagram || '' },
    { platform: 'telegram', url: links?.telegram ? `https://t.me/${links?.telegram}` : '', username: links?.telegram || '' },
    { platform: 'whatsApp', url: links?.whatsapp ? `https://wa.me/${links?.whatsapp}` : '', username: links?.whatsapp || '' },
    { platform: 'twitter', url: links?.twitter ? `https://x.com/${links.twitter}` : '', username: links?.twitter || '' },
    { platform: 'youtube', url: links?.youtube ? `https://youtube.com/@${links.youtube}` : '', username: links?.youtube || '' },
  ], [links]);

  const renderView = () => {
    if (mode === 'view') {
      return socialLinks
        .filter((link) => link.url && link.username)
        .map((link) => <SocialMediaItemView link={link} key={link.username} />);
    }

    const linksWithData = socialLinks.filter(
      (link) => link.url || link.username,
    );
    const linksWithoutData = socialLinks.filter(
      (link) => !link.url && !link.username,
    );

    return (
      <>
        {linksWithData.map((link) => (
          <SocialMediaItemEdit
            platform={link.platform}
            key={link.platform}
            isHasDate
            userName={link.username}
            onClose={onClose}
          />
        ))}
        {linksWithoutData.map((link) => (
          <SocialMediaItemEdit platform={link.platform} key={link.platform} onClose={onClose} />
        ))}
      </>
    );
  };

  return (
    <div className={clsx(styles.container, { [styles.container_view]: (isMobile || isTablet) && mode === 'view' })}>
      {isDesktop && (
        <h2
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: t('common:shareProfile.socialMedia.title'),
          }}
        />
      )}
      <div
        className={styles.networks}
      >
        {renderView()}
      </div>
    </div>
  );
};
