import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import { getDeclension } from '@/modules/shared/helpers';
import { Button } from '@/modules/shared/components';
import { Me } from '@/modules/me/types';
import { MenuShareProfileButton } from '@/modules/me/components';
import { useFavoritesContext } from '@/modules/favorites';
import { useAuthContext } from '@/modules/auth';
import { profileBasePath } from '@/modules/shared/const';
import { CompanyStatusEnum, type CompanyStatus } from '@/modules/company/types';
import { MenuBlackFridayBanner } from '@/modules/contests/black-friday/ui/menu-banner';
import { type UserType, UserTypeEnum } from '@/modules/users';
import { UserMenuLink } from './user-menu-link.component';
import styles from '../personal-account-mobile.module.scss';

interface Props {
  user: Me;
  status: CompanyStatus | undefined;
  type: UserType | undefined;
}

export const LinksBlock = ({ user, status, type }: Props) => {
  const { t } = useTranslation('common');
  const [showModal, setShowModal] = useState(false);
  const { logout } = useAuthContext();
  const { totalCount } = useFavoritesContext();

  const eventsCount = useMemo(() => getDeclension({
    count: user.my_events_count,
    t,
    singular: 'userSidebar.secondaryText.events.one',
    few: 'userSidebar.secondaryText.events.few',
    many: 'userSidebar.secondaryText.events.many',
  }), [t, user]);

  const favsCount = useMemo(() => getDeclension({
    count: totalCount || 0,
    t,
    singular: 'userSidebar.secondaryText.favs.one',
    few: 'userSidebar.secondaryText.favs.few',
    many: 'userSidebar.secondaryText.favs.many',
  }), [t, totalCount]);

  const ratingCount = useMemo(() => getDeclension({
    count: user.reviews_received_count,
    t,
    singular: 'userSidebar.secondaryText.rating.one',
    few: 'userSidebar.secondaryText.rating.few',
    many: 'userSidebar.secondaryText.rating.many',
  }), [t, user]);

  const feedbackCount = useMemo(() => getDeclension({
    count: user.reviews_given_count,
    t,
    singular: 'userSidebar.secondaryText.feedback.one',
    few: 'userSidebar.secondaryText.feedback.few',
    many: 'userSidebar.secondaryText.feedback.many',
  }), [t, user]);

  const links = useMemo(() => ([
    {
      title: t('userSidebar.myEvents'),
      secondaryText: t(eventsCount, { count: 1 }),
      href: `/${profileBasePath}/posters`,
    },
    {
      title: t('userSidebar.favs'),
      secondaryText: t(favsCount, { count: 1 }),
      href: `/${profileBasePath}/favorites`,
    },
    {
      title: t('userSidebar.rating'),
      secondaryText: t(ratingCount, { count: 1 }),
      href: `/${profileBasePath}/rating`,
    },
    {
      title: t('userSidebar.feedback'),
      secondaryText: t(feedbackCount, { count: 1 }),
      href: `/${profileBasePath}/my-feedback`,
    },
    {
      title: t('userSidebar.company'),
      secondaryText: (status === CompanyStatusEnum.Pending || status === CompanyStatusEnum.Proceeding)
        ? t('userSidebar.assessment')
        : t('userSidebar.secondaryText.company'),
      href: `/${profileBasePath}/become-company`,
      secondaryActive: status === CompanyStatusEnum.Pending || status === CompanyStatusEnum.Proceeding,
    },
    {
      title: status === CompanyStatusEnum.Approved
        ? t('userSidebar.credentialsCompany')
        : t('userSidebar.credentials'),
      secondaryText: status === CompanyStatusEnum.Approved
        ? t('userSidebar.secondaryText.accountingInfoCompany')
        : t('userSidebar.secondaryText.accountingInfo'),
      href: `/${profileBasePath}/account`,
    },
    {
      title: t('userSidebar.questions'),
      secondaryText: t('userSidebar.secondaryText.questions'),
      href: '/bazaar/faq',
    },
  ]), [t, status]);

  const handleButtonClick = useCallback(
    () => setShowModal((prev) => !prev),
    [setShowModal],
  );

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleCloseModal();
    }
  }, [handleCloseModal]);

  useEffect(() => {
    if (showModal) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [showModal, handleKeyDown]);

  return (
    <section className={styles.links_block}>
      {type === UserTypeEnum.Company && <MenuShareProfileButton /> }
      <MenuBlackFridayBanner />
      {links.map((link) => (
        <UserMenuLink
          key={link.title}
          title={link.title}
          secondaryText={link.secondaryText}
          href={link.href}
          secondaryActive={link.secondaryActive}
          style={
            ((!user.can_become_company
              || status === CompanyStatusEnum.Approved
              || status === CompanyStatusEnum.Rejected)
              && link.href.includes('become-company'))
              ? { display: 'none' }
              : {}
          }
        />
      ))}
      <Button
        appearance="secondary"
        buttonColor="blue"
        buttonSize="m"
        fullWidth
        onClick={handleButtonClick}
        className={styles.logout}
      >
        {t('userSidebar.logoutMobile')}
      </Button>
      {showModal && (
        <div className={styles.background} onClick={handleCloseModal}>
          <div className={styles.modal} onClick={(event) => event.stopPropagation()}>
            <p>{t('userSidebar.confirmLogout')}</p>
            <div className={styles.buttons_wrapper}>
              <button
                className={styles.exit_button}
                onClick={logout}
                type="button"
              >
                {t('yes')}
              </button>
              <button
                className={styles.exit_button}
                onClick={handleCloseModal}
                type="button"
              >
                {t('no')}
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
