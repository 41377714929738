import React, { useEffect, useMemo, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { IEvent } from '@/modules/events';

export const SeoPosters = ({ posters }: { posters: IEvent[] }) => {
  const router = useRouter();
  const cityCodename = router.query.city;

  const { t: translateSeo } = useTranslation('seo-catalog');
  const { t: translateCity } = useTranslation('seo.common');
  const locationName = translateCity(`countries.th.${cityCodename}`);

  const [baseUrl, setBaseUrl] = useState('');

  useEffect(() => {
    setBaseUrl(window.location.origin);
  }, []);

  const seoConfig = useMemo(
    () => ({
      title: translateSeo('posters.all.title', { city: locationName }),
      description: translateSeo('posters.all.description', {
        city: locationName,
      }),
      canonical: `${baseUrl}/${router.locale}/${cityCodename}/posters`,
    }),
    [translateSeo, locationName, router.locale],
  );

  const openGraph = useMemo(
    () => ({
      type: 'website',
      site_name: 'Bazaar',
      title: seoConfig?.title,
      description: seoConfig?.description,
      url: seoConfig?.canonical,
      images: posters?.map((el, i) => {
        const url = el.images?.[0]?.thumbnail || '';
        return {
          url,
          alt: `Search item ${i + 1}`,
          secureUrl: url,
          width: 1200,
          height: 630,
          type: `image/${url?.split('.')?.at(-1) || ''}`,
        };
      }),
    }),
    [seoConfig, posters],
  );

  const itemListJsonLd = useMemo(() => {
    const itemList = posters?.map((event) => ({
      '@type': 'Event',
      name: event.title,
      startDate: `${event.event_date.split('.').reverse().join('-')}T${event.event_time}.000Z`,
      description: event.description,
      image: event.images?.[0]?.thumbnail || '',
      location: {
        '@type': 'Place',
        name: `${event?.district?.name || ''}, ${event?.city?.name || ''}`,
        address: {
          '@type': 'PostalAddress',
          addressLocality: `${event?.district?.name || ''}, ${event?.city?.name || ''}`,
          addressCountry: 'TH',
        },
      },
      offers: {
        '@type': 'Offer',
        price: event.price,
        priceCurrency: 'THB',
      },
    }));

    return JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      itemListElement: itemList,
    });
  }, [posters]);


  return (
    <>
      <NextSeo {...seoConfig} openGraph={openGraph} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: itemListJsonLd }}
      />
    </>
  );
};
