import { IEvent } from '@/modules/events';

enum SortOrder {
  ASCENDING = 'ascendingFavEvents',
  DECREASING = 'descendingFavEvents',
  UPCOMING = 'upcoming',
}

export const eventsSortingHelper = (events: IEvent[], order: string) => events.sort((a, b) => {
  switch (order) {
    case SortOrder.ASCENDING:
      return a.price - b.price;
    case SortOrder.DECREASING:
      return b.price - a.price;
    case SortOrder.UPCOMING:
      return new Date(a.event_date).getTime() - new Date(b.event_date).getTime();
    default:
      return new Date(a.event_date).getTime() - new Date(b.event_date).getTime();
  }
});
