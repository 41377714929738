import { infiniteListQueryFactory } from '@/modules/shared/queries';
import { ChatApiListRequestConfig } from '@/modules/shared/api';
import { ChatApi } from '../api';

export const BASE_KEY = 'messages';


export const buildListQueryKeyFactory = () => (options) => {
  const {
    filters,
  } = options;
  return [BASE_KEY, filters];
};

const fetchInfiniteListFactory = (listApi) => async ({ pageParam, queryKey }) => {
  const [, filters] = queryKey;
  return listApi({
    url: pageParam,
    filters,
  });
};


export const useInfinityMessages = infiniteListQueryFactory(
  (params) => ChatApi.loadChatMessages({
    ...params?.filters,
    ...params,
  } as ChatApiListRequestConfig),
  [BASE_KEY],
  buildListQueryKeyFactory,
  fetchInfiniteListFactory,
);
