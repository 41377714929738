import React, { useState } from 'react';
import type { IAd } from '@/modules/ads/types';
import { AdImage } from '@/modules/ads/components/ad-image';
import { AdLink } from '@/modules/ads/components/ad-link';
import { ClickAwayListener } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import { useTranslateLocation } from '@/modules/locations';
import {
  AppTooltip, Button, Checkbox, SvgIcon,
} from '@/modules/shared/components';
import { useFormatDataPrice, useMatchMedia } from '@/modules/shared/hooks';
import styles from './mobile-my-ad-card.module.scss';

interface MobileMyAdCardProps {
  ad: IAd;
  adType: string;
  isChoosing: boolean;
  isSelected: number[];
  onSelect: (id: React.SetStateAction<number>) => void;
  onClick: (id: React.SetStateAction<number>) => void;
}

export const MobileMyAdCard = ({
  ad, adType, onSelect, isSelected, onClick, isChoosing,
}: MobileMyAdCardProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('userProfile');
  const translateLocations = useTranslateLocation(
    ad?.city.codename,
    ad?.district?.codename,
  );
  const priceDisplayFormat = useFormatDataPrice();
  const { isMobile } = useMatchMedia();

  return (
    <div className={styles.mobile_card}>
      <div className={styles.image}>
        <AdLink ad={ad}>
          {ad.images[0] ? (
            <AdImage ad={ad} />
          ) : (
            <SvgIcon name={isMobile ? 'mobile-b_no_bg' : 'b_no_bg'} />
          )}
        </AdLink>
      </div>
      <div className={styles.card_content}>
        <div className={styles.ad_info}>
          <div className={styles.title}>
            <p>
              {adType === 'drafts' ? (
                t('myAds.notSpecifiedPrice')
              ) : (
                <>
                  {priceDisplayFormat({
                    priceType: ad?.price_type,
                    price: ad?.price,
                  })}
                  {ad?.price_type === 'for_money' && <SvgIcon name="THB" />}
                </>
              )}
            </p>
            <AdLink ad={ad} className={styles.title}>
              <AppTooltip title={ad.title}>
                <h4>
                  {adType === 'drafts'
                    ? t('myAds.notSpecifiedTitle')
                    : ad?.title}
                </h4>
              </AppTooltip>
            </AdLink>
          </div>
          <div className={styles.location}>
            <SvgIcon name="geo-point" />
            <p>{translateLocations}</p>
          </div>
        </div>
        <div className={styles.actions}>
          {isChoosing ? (
            <Checkbox
              checkboxSize="24"
              checked={!!isSelected.includes(ad?.id)}
              onChange={() => onSelect(ad?.id)}
            />
          ) : (
            <>
              {adType === 'active' && (
                <SvgIcon
                  name="eye-opened"
                  className={styles.hide}
                  onClick={() => onClick(ad?.id)}
                />
              )}
              {adType === 'hidden' && (
                <SvgIcon
                  name="eye-closed"
                  className={styles.open}
                  onClick={() => onClick(ad?.id)}
                />
              )}
              {adType === 'drafts' && (
                <div className={styles.drafts_buttons}>
                  {isMobile && (
                    <SvgIcon
                      name="vertical-menu"
                      className={styles.menu}
                      onClick={() => setIsOpen(!isOpen)}
                    />
                  )}

                  {!isMobile && (
                    <>
                      <SvgIcon name="pencil" className={styles.edit} />
                      <SvgIcon
                        name="trash-bin"
                        className={styles.delete}
                        onClick={() => onClick(ad?.id)}
                      />
                    </>
                  )}
                  {isMobile && isOpen && (
                    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                      <div className={styles.action_modal}>
                        <Button
                          appearance="secondary"
                          buttonColor="black"
                          buttonSize="s"
                          className={styles.edit_button}
                          fullWidth
                        >
                          {t('myAds.edit')}
                          <SvgIcon name="pencil" />
                        </Button>
                        <Button
                          appearance="secondary"
                          buttonColor="black"
                          buttonSize="s"
                          fullWidth
                          className={styles.delete_button}
                          onClick={() => onClick(ad?.id)}
                        >
                          {t('myAds.delete')}
                          <SvgIcon name="trash-bin" />
                        </Button>
                      </div>
                    </ClickAwayListener>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
