import { IAd } from '@/modules/ads/types';

enum SortOrder {
  DEFAULT = 'default',
  INCREASING = 'increasing',
  DECREASING = 'decreasing',
  OLDEST = 'oldest',
  NEWEST = 'newest',
}

const parseDate = (dateString: string): Date => {
  const [day, month, year] = dateString.split('.').map(Number);
  return new Date(year, month - 1, day);
};

export const adsSortingHelper = (ads: IAd[], order: string) => ads.sort((a, b) => {
  switch (order) {
    case SortOrder.INCREASING:
      return a.price - b.price;
    case SortOrder.DECREASING:
      return b.price - a.price;
    case SortOrder.NEWEST:
      return parseDate(b.created_at).getTime() - parseDate(a.created_at).getTime();
    case SortOrder.OLDEST:
      return parseDate(a.created_at).getTime() - parseDate(b.created_at).getTime();
    default:
      return parseDate(b.created_at).getTime() - parseDate(a.created_at).getTime();
  }
});
