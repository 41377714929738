import React, { useEffect, useState } from 'react';

import { Button } from '../button';
import { SvgIcon } from '../svg-icon';

interface ShareButtonProps {
  title?: string;
  url?: string;
  className?: string;
  iconStyle?: React.CSSProperties;
  iconName?: string;
  option?: 'primary' | 'secondary';
}

export const ShareButton = ({
  title,
  url,
  className,
  iconStyle = { fontSize: '24px' },
  iconName = 'share',
  option = 'primary',
}: ShareButtonProps) => {
  const [isClient, setIsClient] = useState(false);
  const [canShare, setCanShare] = useState(false);

  useEffect(() => {
    setIsClient(true);
    if (navigator.canShare) {
      setCanShare(navigator.canShare({ title, url: url || '' }));
    }
  }, [title, url]);

  if (!isClient || !canShare) return null;

  const handleShare = async () => {
    try {
      await navigator.share({
        title,
        url: url || window.location.href,
      });
    } catch (error) {
      console.error(`Couldn't share: ${error}`);
    }
  };

  return (
    <>
      {option === 'primary' && (
        <button type="button" onClick={handleShare} className={className}>
          <SvgIcon name={iconName} style={iconStyle} />
        </button>
      )}

      {option === 'secondary' && (
        <Button
          buttonSize="s"
          buttonColor="black"
          appearance="primary"
          className={className}
          fullWidth
          onClick={handleShare}
        >
          {title}
        </Button>
      )}
    </>
  );
};
