import { fetchList, api } from '@/modules/shared/api';
import { Filters } from '@/modules/ads/components/filter/types';
import { endpoints } from './endpoints';
import { CategoryResponse, Category } from '../types';


const tempDisabledTransportSubCategories = ['rental', 'buy'];


export const CategoriesApi = {
  list: async (config = {}) => {
    const data = await fetchList<CategoryResponse[]>(endpoints.list, config);
    const tempFix = data.map((item) => {
      if (item.codename !== 'vehicles-rental') {
        return item;
      }

      return {
        ...item,
        sub_categories: item.sub_categories
          .filter((subCategory) => !tempDisabledTransportSubCategories.includes(subCategory.codename)),
      };
    });
    return { data: tempFix };
  },

  loadFilters: async (categoryId?: number, subCategoryId?: number, underSubCategoryId?: number): Promise<Filters> => {
    if (!categoryId) {
      return [{
        type: 'location' as const,
        params: { city: 'city', district: 'district' },
      }, {
        type: 'price' as const,
        params: { minPrice: 'min_price', maxPrice: 'max_price' },
      }, {
        name: 'announcement_source',
        type: 'checkbox_list' as const,
        choices: ['company', 'individual'],
      }];
    }
    const response = await api.get<{
      filters: Filters
    }>(underSubCategoryId
      ? endpoints.getUnderSubCategory(underSubCategoryId)
      : (subCategoryId ? endpoints.getSubCategory(subCategoryId)
        : endpoints.getCategory(categoryId)));

    return response.data.filters;
  },

  toplist: async (size: number): Promise<Category[]> => {
    const { data } = await api.get<Category[]>(endpoints.toplist(size));
    return data;
  },
};
