import React, { memo, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useMatchMedia } from '@/modules/shared/hooks';
import { Button, Sorting, SvgIcon } from '@/modules/shared/components';
import styles from './ads-infinity-page.module.scss';

interface AdsSortingProps {
  order: string;
  onOrderChange: (value: string) => void;
  onShowFilterModal?: () => void;
}

export const AdsSorting = memo(({ order, onOrderChange, onShowFilterModal }: AdsSortingProps) => {
  const { t } = useTranslation();
  const { isTablet } = useMatchMedia();

  const options = useMemo(() => [
    { value: '-created_at', label: t('adv:filters.sortingModal.newest') },
    { value: 'created_at', label: t('adv:filters.sortingModal.oldest') },
    { value: '-price', label: t('adv:filters.sortingModal.decreasing') },
    { value: 'price', label: t('adv:filters.sortingModal.increasing') },
  ], [t]);

  return (
    <div className={styles.sorting_wrapper}>
      <Sorting
        value={order}
        setValue={onOrderChange}
        options={options}
        showBackButton={false}
        shouldDisabled
      />
      {isTablet && (
        <Button
          buttonSize="xs"
          buttonColor="grey"
          appearance="primary"
          className={styles.filter_button}
          onClick={onShowFilterModal}
        >
          <SvgIcon name="filter_1" />
          {t('filters-components:title.title')}
        </Button>
      )}
    </div>
  );
});
