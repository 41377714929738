import { useQuery, useQueryClient } from '@tanstack/react-query';
import { SearchApi } from '../api';
import { SEARCH_SUGGESTIONS } from '../consts';

interface SearchSuggestionsProps {
  search?: string;
  max_suggestions?: number;
}

export const useSearchSuggestions = ({
  search,
  max_suggestions,
}: SearchSuggestionsProps) => {
  const queryClient = useQueryClient();
  const query = { search, max_suggestions };

  return useQuery({
    queryKey: [SEARCH_SUGGESTIONS, search, max_suggestions],
    queryFn: () => SearchApi.getSuggestions(query),
    initialData: [],
    enabled: !!search,
    placeholderData: () => queryClient.getQueryData([SEARCH_SUGGESTIONS]) || [],
  });
};
