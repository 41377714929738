import React from 'react';
import clsx from 'clsx';
import { ModalMobile, ModalDesktop } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { useMyCompany } from '@/modules/company/queries';
import type { Company, SocialNetworks } from '@/modules/company/types';
import { useUserProfile } from '@/modules/user-profile/queries';
import { useShareProfileContext } from '../../providers';
import { ProfileSummary } from './profile-summary';
import { SocialMedia } from './social-media';
import { ActionButtons } from './action-buttons';
import styles from './profile-modal.module.scss';

const isSocialNetworksEmpty = (socialNetworks: SocialNetworks | null): boolean => {
  if (!socialNetworks) return true;
  return Object.values(socialNetworks).every((value) => value === null);
};

interface ProfileModalProps {
  open: boolean;
  onClose: () => void;
  externalCompanyData?: Company | undefined;
}

export const ProfileModal = ({ open, onClose, externalCompanyData }: ProfileModalProps) => {
  const { mode } = useShareProfileContext();
  const { data: myCompanyData } = useMyCompany(mode === 'edit');
  const companyData = externalCompanyData ?? myCompanyData;

  const { data: userData } = useUserProfile(companyData?.user);
  const { isMobile, isTablet, isDesktop } = useMatchMedia();

  if (!companyData || !userData) return null;
  const isViewNoNetwork = mode === 'view' && companyData && isSocialNetworksEmpty(companyData.social_networks);
  const idCompany = companyData.custom_page_url ?? companyData.id;

  const ModalContent = () => companyData && userData && (
    <div className={clsx(styles.modal, { [styles.modal_view_no_network]: isViewNoNetwork })}>
      <div className={styles.modal_wrapper}>
        <div className={styles.profile_details}>
          <ProfileSummary companyData={companyData} userData={userData} mode={mode} />
        </div>
        {(!isMobile && !isTablet) && <ActionButtons mode={mode} id={idCompany} onClose={onClose} />}
      </div>

      {(mode === 'edit' || (mode === 'view' && !isSocialNetworksEmpty(companyData.social_networks))) && (
        <div className={styles.social_media}>
          <SocialMedia links={companyData.social_networks} mode={mode} onClose={onClose} />
        </div>
      )}

      {(isMobile || isTablet) && <ActionButtons mode={mode} id={idCompany} onClose={onClose} />}
    </div>
  );

  return isDesktop ? (
    <ModalDesktop open={open} onClose={onClose} isClean>
      <ModalContent />
    </ModalDesktop>
  ) : (
    (isMobile || isTablet) && (
      <ModalMobile
        open={open}
        showCloseButton={mode === 'view'}
        onClose={onClose}
        fullHeight
        isClean
        showBackButton={mode === 'edit'}
        onClickBack={onClose}
      >
        <ModalContent />
      </ModalMobile>
    )
  );
};
