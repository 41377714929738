import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { createPortal } from 'react-dom';
import Loadingmain from '@/components/shared/skeleton/loadingmain';
import { useMeContext, ShareProfileProvider } from '@/modules/me';
import { useMatchMedia } from '@/modules/shared/hooks';
import { isServerSide } from '@/modules/shared/helpers';
import { EmailNotification } from '../email-notification.component';
import styles from './personal-account-layout.module.scss';
import { SideMenu } from '../side-menu';

interface Props {
  children: React.ReactNode;
  headerLeft?: React.ReactNode;
  headerCenter?: React.ReactNode;
  headerRight?: React.ReactNode;
  title?: string;
  hasHeader?: boolean;
}

export const PersonalAccountLayout = (props: Props) => {
  const {
    children, headerLeft, headerCenter, headerRight, title, hasHeader = true,
  } = props;
  const { me: userProfile, isLoading } = useMeContext();
  const { isDesktop, isTablet, isMobile } = useMatchMedia();

  const headerRef = useRef<HTMLElement | null>(null);
  const [margin, setMargin] = useState<number>(0);

  useEffect(() => {
    if (headerRef.current) {
      if (isTablet || isMobile) { // высота хедера + 16 пикселей между хедером и контентом
        setMargin(headerRef.current.getBoundingClientRect().height + 16);
      } else {
        setMargin(0);
      }
    }
  }, [hasHeader, isMobile, isTablet]);

  const root = !isServerSide() && document.querySelector('#email-notification-portal');

  return (
    <ShareProfileProvider mode="edit">
      {!(isTablet || isMobile) && (
        root && createPortal(
          <EmailNotification userProfile={userProfile} />,
          root,
        ) // вставляем уведомление в десктопный хедер
      )}
      <main className={styles.main}>
        {!isDesktop && hasHeader && (
          <header className={styles.header} ref={headerRef}>
            <div className={styles.primary_header}>
              <div className={styles.header_left}>{headerLeft}</div>
              <div className={styles.header_title}>{headerCenter}</div>
              <div className={styles.header_right}>{headerRight}</div>
            </div>
            <div
              className={styles.email_notification}
              id="email_notification_mobile_portal"
            >
              {(isTablet || isMobile) && (
                <EmailNotification userProfile={userProfile} /> // вставляем уведомление в мобильный хедер
              )}
            </div>
          </header>
        )}
        {isLoading && !userProfile && <Loadingmain />}
        {userProfile && (
          <section
            className={clsx(styles.main_section, {
              [styles.main_section__no_header]: !hasHeader,
            })}
            style={{ marginTop: margin ?? 0 }}
          >
            <section className={styles.main_content}>
              {isDesktop && hasHeader && (
                <h1 className={styles.title}>{title}</h1>
              )}
              {children}
            </section>
            {isDesktop && (
              <aside>
                <SideMenu userProfile={userProfile} />
              </aside>
            )}
          </section>
        )}
      </main>
    </ShareProfileProvider>
  );
};
