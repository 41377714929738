import React, { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Button } from '@/modules/shared/components';
import { ProfileModal } from '../profile-modal.component';
import styles from './menu-share-profile-button.module.scss';


type Props = {
  text?: string;
  className?: string;
};

export const MenuShareProfileButton = ({ text, className }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation('common');

  return (
    <>
      <Button
        buttonSize="s"
        buttonColor="green"
        appearance="primary"
        className={className ?? styles.share_button}
        onClick={(v) => setOpenModal(!v)}
        fullWidth
      >
        {text ?? t('shareProfile.menuShareProfileButton')}
      </Button>
      {openModal && (
      <ProfileModal
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
      )}
    </>
  );
};

