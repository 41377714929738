import React from 'react';
import { NextPage } from 'next';
import type { AppContext } from 'next/app';
import { getServerSideCookieValue } from '@/modules/shared/helpers';
import type { Me } from '@/modules/me/types';
import { MeApi } from '@/modules/me/api';

export const withPreloadedAuth = (Component: NextPage) => {
  const Wrapper = (props) => <Component {...props} />;
  Wrapper.getInitialProps = async (app: AppContext) => {
    const pageProps = await Component.getInitialProps?.(app.ctx);

    let accessToken: string | undefined;
    let me: Me | undefined;

    if (app.ctx.req) {
      accessToken = getServerSideCookieValue(app.ctx.req.headers.cookie || '', 'access_token') || '';
    } else {
      console.log('Initial props request is undefined');
    }

    if (accessToken) {
      try {
        me = await MeApi.getMe({ Authorization: `Bearer ${accessToken}` });
      } catch (err) {
        console.error(err);
        accessToken = undefined;
      }
    }

    return { ...pageProps, accessToken, me };
  };

  Wrapper.displayName = `withPreloadedAuth(${Component.displayName})`;
  return Wrapper;
};
