import React, { useCallback } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useMatchMedia } from '../../hooks/use-match-media.hook';
import s from './creation-description.module.scss';
import { Textarea } from '../textarea';
import { MAX_LENGTH, MIN_LENGTH } from './const';
import { formatNumber, validateHtmlInText, validateLinksInText } from '../../helpers';

type CreationDescriptionProps = {
  max_length?: number;
  title?: string;
  required?: boolean;
  heightScrollDesktop?: number;
  heightScrollMobile?: number;
  placeholder?: string;
};

export const CreationDescription = ({
  max_length = MAX_LENGTH, title, required = true, heightScrollDesktop = 220, heightScrollMobile = 180, placeholder,
}: CreationDescriptionProps) => {
  const {
    control, setError, formState: { errors }, clearErrors,
  } = useFormContext<{ description: string }>();
  const { t, lang } = useTranslation();
  const { isMobile } = useMatchMedia();
  const value = useWatch({ name: 'description' });

  const handleBlur = useCallback(() => {
    if (!value.trim()) {
      setError('description', {
        type: 'required',
        message: t('forms:validationErrors.required'),
      });
      return;
    }
    if (value.length < MIN_LENGTH || value.length > max_length) {
      setError('description', {
        type: 'length',
        message: t('forms:validationErrors.description', {
          min: MIN_LENGTH, max: max_length.toLocaleString(),
        }),
      });
      return;
    }
    if (validateLinksInText(value)) {
      setError('description', {
        type: 'links',
        message: t('forms:validationErrors.links_description'),
      });
      return;
    }
    if (validateHtmlInText(value)) {
      setError('description', {
        type: 'html',
        message: t('forms:validationErrors.html_description'),
      });
      return;
    }
    clearErrors('description');
  }, [value, t]);

  return (
    <div className={s.field_inner}>
      <p>
        {title || t('forms:createAd.descriptionTitle')}
        {required && <span> *</span>}
      </p>
      <div className={s.values}>
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <Textarea
              {...field}
              name="description"
              value={value || ''}
              hasErrors={!!errors.description}
              onBlur={handleBlur}
              placeholder={placeholder || t('common:enterWithChars', {
                minChars: MIN_LENGTH, maxChars: formatNumber(max_length, lang),
              })}
              maxChars={max_length}
              countChars
              heightScroll={isMobile ? heightScrollMobile : heightScrollDesktop}
            />
          )}
        />
        {errors.description && (
          <p className={s.error}>{errors.description['0'] || errors.description.message as string}</p>
        )}
      </div>
    </div>
  );
};
