import { DESCRIPTION_REGEXP, HTML_PATTERN, LINK_PATTERN } from '../const';

// Функция для проверки символа 🔞 в кодировке UTF-8 (или UTF-16) и HTML Entity
const checkForUnderEighteenSymbol = (input: string) => {
  // Проверяем для UTF-8 (🔞 как символ)
  const utf8Pattern = /🔞/g;

  // Проверяем для HTML Entity (&#128286; или &#x1F51E;)
  const htmlEntityPattern = /&#(?:x1F51E|128286);/g;

  return utf8Pattern.test(input) || htmlEntityPattern.test(input);
};

export const validateInput = (input: string) => {
  const disallowedSymbolsRegex = new RegExp(
    '\u00a9|\u00ae' // Специальные символы (©, ®)
    + '|\u2000-\u3300' // Символы (например, математические, технические)
    + '|\ud83c[\ud000-\udfff]' // Эмодзи (например, 🀄, 🃏)
    + '|\ud83d[\ud000-\udfff]' // Эмодзи (например, 🐶, 🚗)
    + '|\ud83e[\ud000-\udfff]' // Эмодзи (например, 🤔, 🥳)
    + '|\u2620' // ☠
    + '|\u1F51E', // 🔞 символ вроде должен проверяться, но без checkForUnderEighteenSymbol тест все равно упадет
    'gu',
  );

  // return !disallowedSymbolsRegex.test(input) && !DESCRIPTION_REGEXP.test(input);
  return !disallowedSymbolsRegex.test(input)
    && !DESCRIPTION_REGEXP.test(input)
    && !checkForUnderEighteenSymbol(input);
};

export const validateLinksInText = (input: string) => LINK_PATTERN.test(input);

export const validateHtmlInText = (input: string) => HTML_PATTERN.test(input);
