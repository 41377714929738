import { forwardRef, InputHTMLAttributes, useState } from 'react';
import clsx from 'clsx';
import { SvgIcon } from '../../svg-icon';
import { stopEvent } from '../../../helpers/stop-event.helper';

import styles from './input-password.module.scss';

export type InputTextProps = {
  fullWidth?: boolean;
  error?: boolean;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>;

export const InputPassword = forwardRef<HTMLInputElement, InputTextProps>(({
  fullWidth,
  error,
  disabled,
  value,
  placeholder,
  className = '',
  ...props
}, ref) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div className={styles.wrapper}>
      <input
        {...props}
        ref={ref}
        value={value}
        className={clsx(styles.input, className, {
          [styles.input_error]: error,
        })}
        disabled={disabled}
        placeholder={placeholder}
        type={passwordShown ? 'text' : 'password'}
      />
      <button
        type="button"
        onClick={togglePasswordVisibility}
        className={styles.password_icon}
        onMouseDown={stopEvent}
      >
        <SvgIcon name={passwordShown ? 'eye-opened' : 'eye-closed'} />
      </button>
    </div>
  );
});
