import React from 'react';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { ItemLoader, SvgIcon, UserAvatar } from '@/modules/shared/components';
import { EntityImage } from '@/modules/shared/types';
import { useMatchMedia } from '@/modules/shared/hooks';
import { getUserCompanyData } from '@/modules/shared/helpers';
import { formatTime } from '../../../helpers';
import { StatusMessage } from '../../status-message';
import type { MessageProps, RecipientMessage, SenderMessage } from './message.component';
import styles from './user-message.module.scss';

export const UserMessage = ({
  msg, senderType, messageStatus, onErrorClick, isSupport = false,
}: MessageProps) => {
  const { isDesktop } = useMatchMedia();
  const { lang } = useTranslation();

  if (senderType === 'recipient') {
    const message = msg as RecipientMessage;
    const { name, avatar } = getUserCompanyData(message?.sender?.company, message?.sender);

    return (
      <div className={styles.wrapper_recipient}>
        <div className={styles.container}>
          <div className={styles.container_recipient}>
            {isDesktop && (
              isSupport ? (
                <span className={styles.support_logo}>
                  <SvgIcon name="logo-small" />
                </span>
              ) : (
                <UserAvatar
                  diameter="32px"
                  userName={name}
                  srcAvatar={avatar}
                />
              )
            )}
            {message?.images?.length ? (
              message?.images.map((el) => (
                <img key={el.id} alt="image" src={el.thumbnail} className={styles.message_image} />
              ))
            ) : (
              <p className={styles.recipient}>{message?.message}</p>
            )}
            <p className={clsx(styles.container_recipient_time, styles.time)}>{formatTime(message?.timestamp, lang)}</p>
          </div>
        </div>
      </div>
    );
  }

  if (senderType === 'sender') {
    const message = msg as SenderMessage;

    return (
      <div className={styles.wrapper_sender}>
        <div className={styles.container}>
          <div className={styles.container_status}>
            <StatusMessage
              messageStatus={messageStatus}
              onClick={onErrorClick}
            />
            <p className={styles.time}>{formatTime(message?.timestamp, lang)}</p>
          </div>

          {message?.images?.length ? (
            message?.images.map((el: EntityImage | File) => (
              <div
                key={el instanceof File ? el.name : el.id}
                className={styles.message_image_container}
              >
                <img
                  alt="image"
                  src={el instanceof File ? URL.createObjectURL(el) : el.thumbnail}
                  className={styles.message_image}
                />
                {messageStatus === 'sending' && (
                  <div className={styles.loader}>
                    <ItemLoader isLoading size={30} />
                  </div>
                )}
              </div>
            ))
          ) : (
            <p className={styles.sender}>{message?.message}</p>
          )}
        </div>
      </div>
    );
  }

  return null;
};
