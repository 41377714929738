import React from 'react';
import { useFavoritesContext } from '@/modules/favorites';
import { HaveNothing, HaveAnything } from './components';
import styles from './favourites-page.module.scss';

export const FavouritesPage = () => {
  const { favoritesEvents, favoritesAds } = useFavoritesContext();

  return (
    <section className={styles.favourites_page}>
      {(favoritesEvents.length === 0
          && (favoritesAds.length === 0 || favoritesAds[0]?.announcements.length === 0))
        ? <HaveNothing />
        : (
          <HaveAnything
            events={favoritesEvents || []}
            ads={favoritesAds?.[0]?.announcements}
          />
        )}
    </section>
  );
};
