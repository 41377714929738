import { InputText, SvgIcon } from '@/modules/shared/components';
import useTranslation from 'next-translate/useTranslation';

import {
  useEffect, useRef, useState, SyntheticEvent,
} from 'react';
import styles from './search-text.module.scss';

type SearchTextProps = {
  value: string[]
  onChange: (value: string[]) => void
};
export const SearchText = ({ onChange, value }: SearchTextProps) => {
  const [innerValue, setInnerValue] = useState<string>('');
  const bottomSensorRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!bottomSensorRef.current || !value.length) return;
    bottomSensorRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  }, [value.length]);

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!value.includes(innerValue) && innerValue.trim()) {
      onChange([...value, innerValue.trim()]);
    }
    setInnerValue('');
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputWrapper}>
        <form
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <InputText
            onChange={(e) => setInnerValue(e.target.value)}
            value={innerValue}
            onBlurCapture={handleSubmit}
            name="search"
            className={styles.input}
            placeholder={t('filters-components:search-text.placeholder')}
          />
          {!!innerValue && (
            <button type="submit" className={styles.addBtn}><SvgIcon name="plus-with-bg" /></button>
          )}
        </form>
      </div>
      {!!value?.length && (
        <>
          <div className={styles.items}>
            {value.map((item, index) => (
              <div key={index}>
                <div className={styles.itemsName}>{item}</div>
                <button onClick={() => onChange(value.filter((v) => v !== item))}><SvgIcon name="close" /></button>
              </div>
            ))}
          </div>
          <div ref={bottomSensorRef} />
        </>
      )}

    </div>
  );
};
