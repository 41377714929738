import { useCallback } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { useMatchMedia } from '../../hooks/use-match-media.hook';
import { InputText } from '../inputs';
import s from './creation-title.module.scss';
import { MAX_LENGTH, MIN_LENGTH } from './const';
import { validateHtmlInText, validateLinksInText } from '../../helpers';

type CreationTitleProps = {
  title: string;
  placeholder: string;
  required?: boolean;
  tip?: string;
};

export const CreationTitle = ({
  title, placeholder, required = true, tip,
}: CreationTitleProps) => {
  const {
    control, setError, formState: { errors }, clearErrors,
  } = useFormContext<{ title: string }>();
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();
  const value = useWatch({ name: 'title' });

  const handleBlur = useCallback(() => {
    if (!value.trim()) {
      setError('title', {
        type: 'required',
        message: t('forms:validationErrors.required'),
      });
      return;
    }
    if (value.length < MIN_LENGTH || value.length > MAX_LENGTH) {
      setError('title', {
        type: 'length',
        message: t('forms:validationErrors.title', { min: MIN_LENGTH, max: MAX_LENGTH }),
      });
      return;
    }
    if (validateLinksInText(value)) {
      setError('title', {
        type: 'links',
        message: t('forms:validationErrors.links_title'),
      });
      return;
    }
    if (validateHtmlInText(value)) {
      setError('title', {
        type: 'html',
        message: t('forms:validationErrors.html_title'),
      });
      return;
    }
    clearErrors('title');
  }, [value, t]);

  return (
    <div className={s.field_inner}>
      <p>
        {title}
        {required && <span> *</span>}
      </p>
      <div className={s.values}>
        {tip && isMobile && <p className={s.tip}>{tip}</p>}
        <div className={s.input_wrapper}>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <InputText
                {...field}
                fullWidth
                placeholder={placeholder}
                error={!!errors.title}
                maxLength={MAX_LENGTH}
                onBlur={handleBlur}
              />
            )}
          />
          <span className={tip && isMobile ? clsx(s.length, s.length_company) : s.length}>
            {`${value?.length || 0}/${MAX_LENGTH}`}
          </span>
          {errors.title && (
            <p className={s.error}>{errors.title['0'] || errors.title.message as string}</p>
          )}
        </div>
        {tip && !isMobile && <p className={s.tip}>{tip}</p>}
      </div>
    </div>
  );
};
