import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { useMatchMedia } from '@/modules/shared/hooks';
import { useTranslateLocation } from '@/modules/locations';
import { Button, SvgIcon, TextByLines } from '@/modules/shared/components';
import { UserTypeEnum, type UserType } from '@/modules/users/types';
import { IAd } from '../../types';
import { Value } from './value.component';
import s from './product-info.module.scss';
import { MapLocation } from './map-location.component';

interface Props {
  data: IAd;
  user_type: UserType;
}

export const ProductInfo = ({ data, user_type }: Props) => {
  const { t } = useTranslation('');
  const { isMobile } = useMatchMedia();
  const [isListOpen, setIsListOpen] = useState(false);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const [isDescriptionExpandable, setIsDescriptionExpandable] = useState<boolean>();
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const translatedLocation = useTranslateLocation(
    data?.city?.codename,
    data?.district?.codename,
  );

  useEffect(() => {
    if (descriptionRef && descriptionRef.current) {
      const { current } = descriptionRef;
      setIsDescriptionExpandable(current.scrollHeight > current.offsetHeight);
    }
  }, [descriptionRef]);

  const toggleOpenDescription = useCallback(() => setIsDescriptionOpen((prev) => !prev), []);
  const toggleOpenList = useCallback(() => setIsListOpen((prev) => !prev), []);

  const entries = useMemo(
    () => Object.entries(data.specifications),
    [data.specifications],
  );

  const itemsToShow = useMemo(() => {
    const list = entries
      .filter((el) => !!el[1]
        && (el[0] === 'guide' || ( // guide не попадает в additional_options
          typeof el[1] !== 'boolean' // пока на бэке ещё не все булевые характеристики попадают в additional_options, поэтому нужно это условие
          && !el[0].includes('id')
          && !el[0].includes('price')
          && el[0] !== 'operation_type'
          && el[0] !== 'additional_options')));

    return list;
  }, [entries]);

  const items = useMemo(() => {
    if (!isMobile) return itemsToShow;
    return itemsToShow.length > 5 && !isListOpen ? itemsToShow.slice(0, 5) : itemsToShow;
  }, [itemsToShow, isMobile, isListOpen]);

  return (
    <section className={s.info_block}>
      <div className={clsx(s.block, s.location)}>
        <h2>{t('specifications:location')}</h2>
        <span>
          <SvgIcon name="geo-point" />
          {translatedLocation}
        </span>
        {data.address && user_type === UserTypeEnum.Company && (
          <MapLocation address={data.address} />
        )}
      </div>
      <div className={clsx(s.block, s.description)}>
        <h2>{t('specifications:description')}</h2>
        <p
          ref={descriptionRef}
          className={clsx({ [s.trimmed]: !isDescriptionOpen })}
        >
          <TextByLines text={data.description} />
        </p>
        {isMobile && isDescriptionExpandable && (
          <Button
            appearance="primary"
            buttonSize="xs"
            buttonColor="white"
            onClick={toggleOpenDescription}
            className={s.show_button}
          >
            {t(`common:${isDescriptionOpen ? 'showLess' : 'showMore'}`)}
          </Button>
        )}
      </div>
      {!!itemsToShow.length && (
        <div className={clsx(s.block, s.characteristics)}>
          <h2>{t('specifications:characteristicTitle')}</h2>
          <div className={s.columns}>
            {items.map((el) => (
              <Value
                key={el[0]}
                property={el[0]}
                value={el[1] as string | number | boolean | null}
                subcategory={data.sub_category_codename}
              />
            ))}
          </div>
          {isMobile && itemsToShow.length > 5 && (
            <Button
              appearance="primary"
              buttonSize="xs"
              buttonColor="white"
              onClick={toggleOpenList}
              className={s.show_button}
            >
              {t(`common:${isListOpen ? 'showLess' : 'showMore'}`)}
            </Button>
          )}
        </div>
      )}
      {!!data.specifications.additional_options?.length && (
        <div className={clsx(s.block, s.additional)}>
          <h2>{t('specifications:moreParameters')}</h2>
          <div>
            {data.specifications.additional_options.map((el) => (
              <span key={el}>
                <SvgIcon name="check" />
                {t(`filters:checkbox-list.additional_options.options.${el}`)}
              </span>
            ))}
          </div>
        </div>
      )}
    </section>
  );
};
