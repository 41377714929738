import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { toast } from 'react-toastify';
import useTranslation from 'next-translate/useTranslation';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ItemLoader, SvgIcon } from '@/modules/shared/components';
import { useImageUploader } from '@/modules/shared/hooks';
import { useCreateAdActionsContext, useCreateAdContext } from '../../context';
import { ImageItem } from './image-item.component';
import fieldStyles from '../part-of-form.module.scss';
import styles from './images.module.scss';

export const Images = () => {
  const { t } = useTranslation('createAd');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { handleImageUpload, loading } = useImageUploader();

  const { images } = useCreateAdContext();
  const { setImages } = useCreateAdActionsContext();
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);

  const moveImage = useCallback((fromIndex: number, toIndex: number) => {
    const updatedImages = Array.from(images);
    const [movedImage] = updatedImages.splice(fromIndex, 1);
    updatedImages.splice(toIndex, 0, movedImage);
    setImages(updatedImages);
  }, [images]);

  const handleFileChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const uploadedImages = await handleImageUpload(e);
      if (uploadedImages.length > 0) {
        if (images.length + uploadedImages.length <= 25) {
          setImages((prevImages) => {
            const newImages = [...prevImages, ...uploadedImages];
            return newImages;
          });
        } else {
          toast.error(t('errors.tooManyPhotosToast'));
        }
      }
    },
    [handleImageUpload, images],
  );

  const handleDeleteImage = useCallback((index: number) => {
    setImages((prevImages) => {
      const newImages = prevImages.filter((_, i) => i !== index);
      return newImages;
    });
  }, []);

  useEffect(() => {
    const newPreviewUrls = images.map((file) => URL.createObjectURL(file as Blob));
    setPreviewUrls(newPreviewUrls);

    return () => {
      newPreviewUrls.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [images]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={fieldStyles.field}>
        <p className={fieldStyles.secondary_title}>
          {t('descriptionAndPhotos.photos.title')}
          <span> *</span>
        </p>
        <div className={styles.images_wrapper}>
          <p className={styles.descriptionFirst}>
            {t('descriptionAndPhotos.photos.descriptionFirst')}
          </p>
          <p className={styles.descriptionSecond}>
            {t('descriptionAndPhotos.photos.descriptionSecond')}
          </p>
          <div className={styles.images}>
            <div
              className={styles.input}
              onClick={() => inputRef.current?.click()}
            >
              <input
                multiple
                type="file"
                ref={inputRef}
                maxLength={11}
                accept=".jpeg,.jpg,.png,.heic,.webp"
                onChange={handleFileChange}
              />
              {!loading && <SvgIcon name="photo" />}
              <ItemLoader isLoading={loading} size={65} />
            </div>
            {images.map((image, index) => (
              <ImageItem
                key={index}
                index={index}
                moveImage={moveImage}
                handleDeleteImage={handleDeleteImage}
                previewUrl={previewUrls[index]}
              />
            ))}
          </div>
        </div>
      </div>
    </DndProvider>
  );
};
