import { UAParser } from 'ua-parser-js';
import React from 'react';
import type { AppContext } from 'next/app';
import { triggerEvent } from '@/modules/rollbar';

export const withInitialDeviceType = (Component) => {
  const Wrapper = (props) => <Component {...props} />;
  Wrapper.getInitialProps = async (app: AppContext) => {
    const [initialPageProps, staticPageProps] = await Promise.allSettled([
      Component.getInitialProps?.(app),
      Component.getStaticProps?.(app),
    ]);

    let mergedProps = {};

    if (initialPageProps.status === 'fulfilled') {
      mergedProps = { ...mergedProps, ...initialPageProps.value };
    }
    if (staticPageProps.status === 'fulfilled') {
      mergedProps = { ...mergedProps, ...staticPageProps.value };
    }

    try {
      const ua = new UAParser(app.ctx.req?.headers['user-agent']);
      const uaInitialDeviceType = ua.getDevice().type || 'desktop';

      const initialMatchMedia = {
        isMobile: uaInitialDeviceType === 'mobile',
        isTablet: uaInitialDeviceType === 'tablet',
        isDesktop: uaInitialDeviceType === 'desktop',
      };

      return { ...mergedProps, initialMatchMedia };
    } catch (e) {
      triggerEvent(e, 'Failed to get initial device type');
      return { ...mergedProps };
    }
  };
  return Wrapper;
};
