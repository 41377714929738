import React, { useCallback, useEffect, useState } from 'react';
import Link from 'next/link';
import Cookie from 'js-cookie';
import { useMatchMedia } from '@/modules/shared/hooks';
import {
  LangSwitcherDesktop, LangSwitcher, SvgIcon, UserAvatar, CountNotification,
} from '@/modules/shared/components';
import { useLanguageMutation, useMeContext } from '@/modules/me';
import { AuthProtectedLink, useAuthContext } from '@/modules/auth';
import { useFavoritesContext } from '@/modules/favorites';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import setLanguage from 'next-translate/setLanguage';
import { Locale } from '@/modules/shared/types';
import { profileBasePath } from '@/modules/shared/const';
import { useUserType } from '@/modules/users/hooks';
import { UserTypeEnum } from '@/modules/users';
import { useChatDataContext } from '@/modules/chat/providers';
import { useUpdateEffect } from '@react-hookz/web';
import { LocationClarification } from '../index-page';
import { HeaderProps, languages } from './types';
import s from './primary-header.module.scss';
import { ADD_PRODUCT_PATH, ADD_EVENT_PATH } from './consts';
import { HeaderLinks } from './header-links.component';


const getLanguage = (locale: Locale) => languages
  .find((language) => language.locale === locale) || languages[0];

const getLocale = () => Cookie.get('NEXT_LOCALE') as Locale;
const setLocale = (code?: string) => Cookie.set('NEXT_LOCALE', code || 'en', { path: '/', expires: 365 });

export const PrimaryHeader = ({
  showCityModal, setShowCityModal, linkRef,
}: HeaderProps) => {
  const router = useRouter();
  const [lang, setLang] = useState(() => languages.find(({ locale }) => locale === router.locale) || languages[1]);
  const [showModal, setShowModal] = useState(false);
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const { t } = useTranslation();
  const { mutate: updateUserLanguage } = useLanguageMutation();
  const { isAuthenticated } = useAuthContext();
  const { me: userData } = useMeContext();
  const { type, companyData } = useUserType(userData?.user_type, userData?.company_id);
  const { unreadDialogsCount } = useChatDataContext();

  const { totalCount } = useFavoritesContext();

  useEffect(() => {
    if (userData?.locale) {
      const userLanguage = getLanguage(userData.locale);
      setLocale(userData.locale);
      setLang(userLanguage);
    }

    const languageFromCookie = getLocale();
    if (!languageFromCookie) {
      const browserLanguage = (navigator.language || navigator.userLanguage)
        .split('-')[0] as Locale;
      const userLanguage = getLanguage(browserLanguage);
      setLocale(userLanguage.locale);
      setLang(userLanguage);
    } else {
      const userLanguage = getLanguage(languageFromCookie);
      if (userLanguage) {
        setLang(userLanguage);
        setLocale(userLanguage.locale);
      }
    }
  }, [userData]);

  const toggleLang = useCallback(async (newLang) => {
    setLang(newLang);
    setLocale(newLang.locale);
    setShowModal(false);
    if (isAuthenticated) {
      updateUserLanguage(newLang.locale);
    }
  }, [isAuthenticated, updateUserLanguage]);

  const isAddEventPath = router.pathname.includes(ADD_EVENT_PATH);
  const isAddProductPath = router.pathname.includes(ADD_PRODUCT_PATH);

  useUpdateEffect(() => {
    setLanguage(lang.locale);
  }, [lang.locale]);

  return (
    <>
      <div className={s.primary_header} style={isAddEventPath && isMobile ? { display: 'none' } : {}}>
        {(isMobile || isTablet) && (
          <div className={s.mobile_wrapper}>
            <HeaderLinks linkRef={linkRef} />
            <div className={s.mobile_header}>
              <div className={s.mobile_header_logo} data-testid="header-logo">
                <Link href="/">
                  <SvgIcon name="logo-main-small" />
                </Link>
              </div>
              <div className={s.mobile_header_tools}>
                <LocationClarification isShowModal={showCityModal} onChange={() => setShowCityModal(!showCityModal)} />
                <LangSwitcher currentLang={lang} toggleLang={toggleLang} />
              </div>
            </div>
          </div>
        )}
        {isDesktop && (
          <div className={s.desktop_header}>
            <div className={s.desktop_header_links}>
              <span className={s.desktop_header_lang}>
                <button type="button" onClick={() => setShowModal(!showModal)}>
                  {lang.label}
                  <SvgIcon name="arrow-down" />
                </button>
                {showModal && (
                  <LangSwitcherDesktop
                    isOpen={showModal}
                    currentLang={lang}
                    onClose={() => setShowModal(!showModal)}
                    toggleLang={toggleLang}
                  />
                )}
              </span>
              <HeaderLinks />
            </div>
            <div className={s.desktop_header_bar}>
              <div className={s.desktop_header_bar_actions}>
                <AuthProtectedLink href={`/${profileBasePath}/favorites`} className={s.desktop_header_bar_actions_favourite}>
                  <SvgIcon name="header-favorite" />
                  <CountNotification
                    count={totalCount}
                    type="header"
                    className={s.desktop_header_bar_actions_favourite_count}
                    color="grey"
                  />
                </AuthProtectedLink>
                <AuthProtectedLink href={`/${profileBasePath}/chat`} className={s.desktop_header_bar_actions_chat}>
                  <SvgIcon name="header-chat" />
                  <CountNotification
                    count={unreadDialogsCount}
                    type="header"
                    className={s.desktop_header_bar_actions_chat_count}
                    color="red"
                  />
                </AuthProtectedLink>
                {(userData && isAuthenticated) ? (
                  <Link href={`/${profileBasePath}/my-ads`}>
                    <UserAvatar
                      userName={
                        type === UserTypeEnum.Company
                          ? (companyData?.name || '')
                          : (userData.full_name || userData.email)
                      }
                      srcAvatar={
                        type === UserTypeEnum.Company
                          ? (companyData?.logo?.thumbnail || '')
                          : userData.avatar
                      }
                      diameter="32px"
                      style={{ border: 'none' }}
                    />
                    <p>{t('common:header.profile')}</p>
                  </Link>
                ) : (
                  <AuthProtectedLink href="/?auth-step=login">
                    <SvgIcon name="header-user" />
                    {t('common:header.login')}
                  </AuthProtectedLink>
                )}
              </div>
              {(!isAddEventPath && !isAddProductPath) && (
                <AuthProtectedLink
                  customHeader="addAds"
                  href={`/${router.locale}/product/create`}
                >
                  <button className={s.desktop_header_bar_ad} data-testid="create_ad_button">
                    {t('common:header.placeAd')}
                  </button>
                </AuthProtectedLink>
              )}
            </div>
          </div>
        )}
      </div>
      <div id="email-notification-portal" style={{ width: '100vw' }} />
    </>
  );
};
