import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';

import { ModalDesktop, SvgIcon } from '@/modules/shared/components';

import { Footer } from '../footer';
import type { LayoutProps } from './types';

import styles from './layout.module.scss';


export const TabletLayout = ({
  hasNotAppliedFilters,
  hasFilters,
  handleBtnFiltering,
  handleReset,
  onChangeVisible,
  isVisible, children,
}: LayoutProps) => {
  const { t } = useTranslation();

  return (
    <ModalDesktop
      open={isVisible}
      showBackButton={false}
      showCloseButton={false}
      className={styles.tablet_filter_modal}
      classNameHeader={styles.tablet_header}
      classNameHeaderContainer={styles.tablet_header_container}
      onClose={onChangeVisible}
      title={(
        <div className={styles.title}>
          {t('filters-components:title.title')}
          <button
            type="button"
            className={styles.close_button}
            onClick={() => onChangeVisible(false)}
          >
            <SvgIcon name="close-icon" style={{ fontSize: '24px' }} />
          </button>
        </div>
      )}
      footer={(
        <Footer
          hasFilters={hasFilters}
          onClickReset={handleReset}
          onClickApply={handleBtnFiltering}
          hasNotAppliedFilters={hasNotAppliedFilters}
        />
      )}
    >
      <div
        className={clsx(styles.tablet, {
          [styles.tablet_footerShowing]: hasFilters || hasNotAppliedFilters,
        })}
      >
        <div className={styles.wrapper}>{children}</div>
      </div>
    </ModalDesktop>
  );
};
