import { isServerSide } from '@/modules/shared/helpers';

export const getInitialSwitchButtonValue = () => {
  if (!isServerSide()) {
    const value = localStorage.getItem('favourites_switch_value');
    if (value) {
      return value;
    }
    return 'announcements';
  }

  return 'announcements';
};
