import { rollbar } from './rollbar.config';

/**
 * Функция для логирования событий в Rollbar.
 *
 * @param {Error} error - Объект ошибки.
 * @param {string | Error} [message] - Сообщение об ошибке (опционально).
 *
 * @example
 * } catch (error) {
 *  triggerEvent({ error }, 'Failed to fetch data');
 * }
 */

export const triggerEvent = (
  error: Error,
  message?: string | Error,
) => {
  try {
    rollbar.error(error, message);
  } catch (e) {
    console.error('Failed to log event to Rollbar');
    console.error(e);
  }
};
