import { useMyCompany } from '@/modules/company/queries';
import { useAuthContext } from '@/modules/auth';
import { UserTypeEnum, type UserType } from '../types';

// используется для определения типа авторизованного пользователя
export const useUserType = (
  user_type: UserType | undefined,
  company_id?: number | null,
) => {
  const { isAuthenticated } = useAuthContext();
  /*
  * нужна зависимость от isAuthenticated, чтобы запрос произошел при смене статуса
  * авторизации для обновления аватарки в хедере при сбросе куков
  * */
  const { data } = useMyCompany(isAuthenticated && user_type === UserTypeEnum.Company);
  const type = company_id ? UserTypeEnum.Company : UserTypeEnum.Individual;

  return { type, companyData: data };
};
