import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { noop } from 'lodash';
import { useAuthContext } from '@/modules/auth';
import { Me } from '../types';
import { useMeQuery } from './me.query';
import { MeApi } from '../api';

interface IMeContext {
  isLoading: boolean;
  me?: Me;
  refetch: () => void;
}
const MeContext = createContext<IMeContext>({
  isLoading: false,
  refetch: noop,
});


export function MeProvider({ children, me }) {
  const { isAuthenticated } = useAuthContext();
  const {
    data, isLoading, initialLoading, refetch,
  } = useMeQuery(isAuthenticated);

  const value = useMemo<IMeContext>(() => ({
    me: data ?? me,
    isLoading: isLoading || initialLoading,
    refetch,
  }), [me, data, isLoading, initialLoading, refetch]);

  useEffect(() => {
    if (data) {
      MeApi.registerDevice({ });
    }
  }, [data]);

  return (
    <MeContext.Provider value={value}>
      {children}
    </MeContext.Provider>
  );
}

export const useMeContext = () => useContext(MeContext);
