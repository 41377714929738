import { api } from '@/modules/shared/api';
import { endpoints } from './endpoints';

export const SearchApi = {
  getSuggestions: async (query: Record<string, string | number | undefined>) => {
    const queryParams = new URLSearchParams();

    Object.entries(query).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== '') {
        queryParams.append(key, String(value));
      }
    });

    const { data } = await api.get(endpoints.getSuggestions(queryParams.toString()));
    return data?.results || [];
  },
};
