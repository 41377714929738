import { POSSIBLE_CITIES } from '@/modules/locations/consts';
import { getServerSideCookieValue } from './get-server-side-cookie-value.helper';

type ReturnType = {
  redirect: {
    destination: string;
    statusCode: 302;
  };
};

export const checkExistedCityAndRedirect = (
  resolvedUrl: string,
  cookieString: string,
): ReturnType | null => {
  const pathParts = resolvedUrl.split('/');
  const currentCity = pathParts[1];

  if (!POSSIBLE_CITIES.includes(currentCity)) {
    const cityFromCookies = getServerSideCookieValue(cookieString, 'selectedCityName');

    if (cityFromCookies) {
      const newUrl = resolvedUrl.replace(currentCity, cityFromCookies);
      return {
        redirect: {
          destination: newUrl,
          statusCode: 302,
        },
      };
    }

    const newUrl = resolvedUrl.replace(currentCity, 'allcities');
    return {
      redirect: {
        destination: newUrl,
        statusCode: 302,
      },
    };
  }

  return null;
};
