
import { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import Cookie from 'js-cookie';
import { User } from '@/modules/users';
import { UserCardType } from '@/modules/shared/types';
import { UserInfo } from '@/modules/shared/components';
import { useAuthContext, useOpenAuthModal } from '@/modules/auth';

import { Company } from '../../../company/types';
import { UserContacts } from './user-contacts.component';
import styles from './user-card.module.scss';

interface UserCardProps {
  card: UserCardType;
  adId?: number;
  adUserId?: number;
  userData: User;
  companyData?: Company;
  openModal?: () => void;
  scrollToReviews?: () => void;
  showReviews?: () => void;
}

export const UserCard = ({
  card,
  adId,
  adUserId,
  userData,
  companyData,
  openModal,
  scrollToReviews,
  showReviews,
}: UserCardProps) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const openAuthModal = useOpenAuthModal();
  const { isAuthenticated } = useAuthContext();
  const isMessageToMyself = Cookie.get('user_id') === String(userData.id);

  useEffect(() => {
    const userIdCookie = Cookie.get('user_id');
    const reviewerId = userIdCookie !== null ? parseInt(String(userIdCookie), 10) : null;
    setIsDisabled(!(adUserId === reviewerId));
  }, [adUserId]);

  const handleProtectedAction = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
    if (!isAuthenticated || isMessageToMyself) event.preventDefault();
    if (!isAuthenticated) openAuthModal('login');
  }, [isAuthenticated, isMessageToMyself]);

  return (
    <div className={clsx(
      styles.user_card,
      { [styles.user_ad_wrapper]: card === 'adUser' },
    )}
    >
      <UserInfo
        card={card}
        userData={userData}
        companyData={companyData}
        isDisabled={isDisabled}
        openModal={openModal}
        showReviews={showReviews}
        scrollToReviews={scrollToReviews}
        goToTelegram={handleProtectedAction}
      />
      <UserContacts
        card={card}
        adId={adId}
        userData={userData}
        companyData={companyData}
        isDisabled={isDisabled}
        handleProtectedAction={handleProtectedAction}
      />
    </div>
  );
};
