import { Controller, useFormContext } from 'react-hook-form';
import { InputText, Button, Checkbox } from '@/modules/shared/components';
import Trans from 'next-translate/Trans';
import Link from 'next/link';
import React from 'react';
import useTranslation from 'next-translate/useTranslation';

import styles from '../../auth-modal.module.scss';

export const CompleteRegistrationScreen = () => {
  const {
    control, watch, formState: { isSubmitting },
  } = useFormContext<{
    agreement: boolean,
    fullName: string,
    password: string,
  }>();
  const agreement = watch('agreement');

  const { t } = useTranslation();

  return (
    <>
      <div className={styles.title}>
        <h2>{t('auth:register.titleStep2')}</h2>
        <div className={styles.subTitle}>{t('auth:register.subTitleStep2')}</div>
      </div>
      <div className={styles.formItem}>
        <div className={styles.formItem__title}>{t('auth:label.name')}</div>
        <Controller
          control={control}
          name="fullName"
          render={({ field, formState: { errors } }) => (
            <>
              <InputText
                {...field}
                error={!!errors.fullName}
                fullWidth
              />
              {errors.fullName && <div className={styles.error}>{errors.fullName.message}</div>}
            </>
          )}
        />
      </div>
      <div className={styles.password}>
        <div className={styles.formItem__title}>{t('auth:label.enterPassword')}</div>
        <Controller
          control={control}
          name="password"
          render={({ field, formState: { errors } }) => (
            <>
              <InputText
                {...field}
                type="password"
                error={!!errors.password}
                fullWidth
              />
              {errors.password && <div className={styles.error}>{errors.password.message}</div>}
            </>
          )}
        />
      </div>
      <div className={styles.agreement}>
        <label>
          <Controller
            control={control}
            name="agreement"
            render={({ field }) => (
              <Checkbox onChange={field.onChange} checked={!!field.value} checkboxSize="24" />
            )}
          />
          <div>
            <Trans
              i18nKey="auth:label.agreement"
              components={[<Link href="/bazaar/rules" target="_blank" />, <Link href="/bazaar/privacy" target="_blank" />]}
            />
          </div>
        </label>
      </div>
      <div>
        <Button
          disabled={!agreement || isSubmitting}
          type="submit"
          fullWidth
          appearance="primary"
          buttonColor="green"
          buttonSize="s"
        >
          {t('auth:register.buttonRegister')}
        </Button>
      </div>
    </>
  );
};
