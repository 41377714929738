import React, {
  Fragment, useCallback, useEffect, useState, useMemo,
} from 'react';
import { Box } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';

import { ItemLoader, HorizontalScrollWithArrows } from '@/modules/shared/components';
import { useInfiniteListRef, useMatchMedia } from '@/modules/shared/hooks';
import { useEventContext } from '@/modules/events/providers';
import { SeoPosters } from '@/modules/seo';
import s from '@/styles/pages/Poster.module.scss';
import { Calendar } from '../calendar';
import { EventCardList } from '../event-card-list';
import { EventCategoryFilter } from '../event-category-filter';
import { useEventsInfiniteListQuery } from '../../queries';


export function PostersPage({ initialEventsData, initialPopularEventsData, cityId }) {
  const { isTablet, isDesktop } = useMatchMedia();

  const { searchFilter, category, onChangeStatusFilter } = useEventContext();
  const { t: translateEvent } = useTranslation('');

  const [filters, setFilters] = useState(() => ({
    ...(cityId && { city: cityId }),
  }));

  const handleDayClick = useCallback(
    (date) => {
      setFilters((prev) => ({ ...prev, event_date: date }));
    },
    [setFilters],
  );

  useEffect(() => {
    setFilters((prev) => ({ ...prev, ...(cityId && { city: cityId }) }));
  }, [cityId, setFilters]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      event_type: category,
      search: searchFilter,
    }));
  }, [category, searchFilter]);

  const {
    hasMore, fetchMore, pages, isLoading,
  } = useEventsInfiniteListQuery(initialEventsData && {
    order: ['event_date', 'event_time'],
    filters,
    pageSize: 12,
  }, initialEventsData && {
    data: initialEventsData,
  });

  const hasEventsOnDate = !(filters?.event_date && !pages?.[0]?.data?.length);
  const hasData = !!pages?.[0]?.data?.length;
  const lastListItemRef = useInfiniteListRef(hasMore, fetchMore, isLoading);


  const allEventData = useMemo(
    () => pages.reduce((acc, page) => acc.concat(page.data), []),
    [pages],
  );

  const {
    hasMore: hasMorePopular, fetchMore: fetchMorePopular, pages: pagesPopular,
  } = useEventsInfiniteListQuery(initialPopularEventsData && {
    order: '-views',
    filters: { ...(cityId && { city: cityId }) },
    pageSize: 12,
  }, initialPopularEventsData && {
    data: initialPopularEventsData,
  });
  const lastListItemPopularRef = useInfiniteListRef(hasMorePopular, fetchMorePopular);

  const popularEventData = useMemo(
    () => pagesPopular.reduce((acc, page) => acc.concat(page.data), []),
    [pagesPopular],
  );


  return (
    <div className={s.posters_container}>
      <SeoPosters posters={initialEventsData || initialPopularEventsData} />
      <div className={s.calendar}>
        <Calendar onDayClick={handleDayClick} />
      </div>
      {(isDesktop || isTablet) && (
        <HorizontalScrollWithArrows buttonArrowWidth={32}>
          <EventCategoryFilter
            onClick={onChangeStatusFilter}
            isActive={category}
          />
        </HorizontalScrollWithArrows>
      )}

      <div className={s.list}>
        <EventCardList data={allEventData} />
        {pages.map((page, i) => (
          <Fragment key={i}>
            {!(i + 1 === pages.length && page.data.length) ? null : (
              <div ref={lastListItemRef} />
            )}
          </Fragment>
        ))}
        {!isLoading ? null : (
          <Box display="flex" justifyContent="center" alignItems="center">
            <ItemLoader isLoading size="50px" />
          </Box>
        )}

        {hasEventsOnDate && !hasData && !isLoading && (
        <div className={s.not_events}>
          <div className={s.not_events_title}>
            <h5>{translateEvent('events:notFound.title')}</h5>
          </div>
          <img src="/assets/mascots/rabbit-not-found.svg" alt="Not found" />
        </div>
        )}

        {/* Этот блок отображается если нет событий на выбранную дату (hasEventsOnDate=false) */}
        {(!hasEventsOnDate && !isLoading) && (
          <div className={s.not_events}>
            <div className={s.not_events_title}>
              <h5>{translateEvent('events:notFound.title')}</h5>
              <p>{translateEvent('events:notFound.description')}</p>
            </div>
            <img src="/assets/mascots/rabbit-not-found.svg" alt="Not found" />
          </div>
        )}

        {(!hasData && !isLoading) && (
        <div className={s.popular}>
          <EventCardList data={popularEventData} title="events:popular" />
          {popularEventData.map((page, i) => (
            <Fragment key={i}>
              {!(i + 1 === popularEventData?.length && page?.data?.length) ? null : (
                <div ref={lastListItemPopularRef} />
              )}
            </Fragment>
          ))}
        </div>
        )}
      </div>
    </div>
  );
}
