import { useCallback } from 'react';
import Cookie from 'js-cookie';
import { useRouter } from 'next/router';
import { useFeatureFlag } from '../feature-flag';
import { profileBasePath } from '../const';
import { isServerSide } from '../helpers';

const hasCurrentVersion = (params: unknown): params is { currentVersion: string } => (
  typeof params === 'object'
  && params !== null
  && 'currentVersion' in params
  && typeof params.currentVersion === 'string'
);

const key = 'RESET_COOKIE';

export const useResetCookieOnce = () => {
  const [enabled, params] = useFeatureFlag(key);
  const { route, push } = useRouter();

  const resetCookie = useCallback((callback: () => void) => {
    if (!enabled || !hasCurrentVersion(params)) {
      console.warn('currentVersion is not defined or is empty');
      return;
    }

    if (!isServerSide()) {
      const resetCookieFlag = Cookie.get(key);
      const { currentVersion } = params;

      if (resetCookieFlag !== currentVersion) {
        const cookies = document.cookie.split(';');

        cookies.forEach((cookie) => {
          const eqPos = cookie.indexOf('=');
          const name = eqPos > -1 ? cookie.slice(0, eqPos).trim() : cookie.trim();
          Cookie.remove(name, { path: '/' });
        });
        callback();

        Cookie.set(key, String(currentVersion), { expires: 365, path: '/' });
        console.info('Cookies were deleted');

        if (route.includes(`/${profileBasePath}/`)) {
          push('/');
        }
      }
    }
  }, [enabled, params, push, route]);

  return { resetCookie };
};
