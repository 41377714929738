import React from 'react';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';
import s from './code-input.module.scss';

export const CodeInput = ({
  control, handleBackspace, handleCodeInput, handlePaste, className = '',
}) => (
  <div className={clsx(s.code_inputs, className)}>
    {Array.from({ length: 6 }, (_, index) => (
      <Controller
        key={index}
        name={`code.${index}`}
        control={control}
        defaultValue=""
        rules={{
          required: true,
          pattern: /\d/,
        }}
        render={({ field }) => (
          <input
            {...field}
            id={`code-input-${index}`}
            type="text"
            autoComplete="off"
            onPaste={handlePaste}
            onKeyDown={handleBackspace}
            onChange={handleCodeInput}
            inputMode="numeric"
          />
        )}
      />
    ))}
  </div>
);
