import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import useTranslation from 'next-translate/useTranslation';
import { useCustomWindowWidth } from '@/modules/shared/hooks';
import { useMeContext } from '@/modules/me';
import { SuggestionFeedbackTypes } from '@/modules/me/components/my-feedback/types';
import { useSuggestionFeedbackList } from '@/modules/me/components/my-feedback/queries';
import { ReviewFormTypes, AnnouncementReviewData } from '../types';
import { useCreateReviewMutation } from '../mutations';
import { CustomModal } from './custom-modal.component';
import { StepOne, StepTwo, StepThree } from './components';
import styles from './leave-feedback-modal.module.scss';

type SuggestionWithoutMeta = Omit<SuggestionFeedbackTypes, 'id' | 'created_at' | 'type'>;

interface Props {
  isOpen: boolean;
  suggestion: SuggestionWithoutMeta;
  onClose: () => void;
  defaultRating?: '1' | '2' | '3' | '4' | '5';
}

/**
 * @param {SuggestionFeedbackTypes} props.suggestion
 * * SuggestionFeedbackTypes props.suggestion - Данные объявления,
 которые нужны для отображения информации во 2 шаге в модальном окне.
 * @param {number} props.defaultRating - на странице "мои отзывы" в списке предложений есть звезды,
 * можно вызвать модальное окно кликом по ним и сразу передать оценку
 * */

export const LeaveFeedbackModal = (props: Props) => {
  const {
    isOpen, suggestion, onClose, defaultRating,
  } = props;
  const { announcement, user } = suggestion;

  const { t } = useTranslation('review');
  const [step, setStep] = useState<1 | 2 | 3>(1);
  const { mutateAsync: createReview, isSuccess } = useCreateReviewMutation();
  const { refetch } = useSuggestionFeedbackList();

  const { me } = useMeContext();

  const adDetail: AnnouncementReviewData | undefined = useMemo(() => {
    const fullName = user.company?.name || user?.full_name || '';

    if (suggestion) {
      return {
        full_name: fullName,
        id: announcement.id,
        title: announcement.title,
        price_type: announcement.price_type,
        price: announcement.price || null,
        main_picture: {
          thumbnail: announcement.main_picture?.thumbnail || '',
        },
      };
    }

    return undefined;
  }, [announcement, user, suggestion]);

  const formMethods = useForm<ReviewFormTypes>({
    defaultValues: {
      announcement: announcement.id,
      recipient: announcement.user,
      deal_state: 'completed',
      rating: defaultRating ?? undefined,
    },
  });
  const { handleSubmit, setValue, reset } = formMethods;

  useEffect(() => {
    if (me) {
      setValue('reviewer', me.id);
    }
  }, [me]);

  useEffect(() => {
    if (defaultRating) {
      setValue('rating', defaultRating);
    }
  }, [defaultRating]);

  const onsubmit: SubmitHandler<ReviewFormTypes> = async (data) => {
    if (data.comment?.length === 0) {
      delete data.comment;
    }
    const response = await createReview(data);

    if (response.id) { // если создался отзыв, вернется объект с отзывом
      setStep(3);
      return;
    }
    if (response?.includes('ALREADY_REVIEWED')) {
      toast.info(t('review:reviewed'));
    }
  };

  const handleClickBackMobile = useCallback(() => {
    if (step === 2) {
      setStep(1);
    }
  }, [step]);

  const handleClose = useCallback(() => {
    reset();
    onClose();
    setStep(1);

    if (isSuccess) refetch();
  }, [onClose, reset, isSuccess, refetch]);

  const { windowWidth } = useCustomWindowWidth();
  const isShowButton = Boolean(step === 2 && windowWidth && windowWidth < 768);

  return (
    <CustomModal
      open={isOpen}
      className={styles.modal}
      fullHeight
      showBackButton={isShowButton}
      onClickBack={handleClickBackMobile}
      title={isShowButton && t('yourReview')}
      showCloseButton
      onClose={handleClose}
    >
      <FormProvider {...formMethods}>
        <form
          className={styles.leave_feedback_form}
          onSubmit={handleSubmit(onsubmit)}
        >
          {step === 1 && <StepOne setStep={setStep} />}
          {step === 2 && (
            <StepTwo
              setStep={setStep}
              adDetail={adDetail}
            />
          )}
          {step === 3 && <StepThree />}
        </form>
      </FormProvider>
    </CustomModal>
  );
};
