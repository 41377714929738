import React, {
  useEffect, useCallback, useMemo, useState,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { useMatchMedia } from '@/modules/shared/hooks';
import { getDeclension } from '@/modules/shared/helpers';
import { Pagination, Sorting } from '@/modules/shared/components';
import { IEvent } from '@/modules/events';
import { EventCard } from '@/modules/events/components/event-card';
import { EVENTS_TYPES } from '@/modules/events/consts';
import { eventsSortingHelper } from '../helpers';
import styles from './favourite-ads.module.scss';

const all = 'all';
const noCategory = 'no_category';

export const FavouriteEvents = ({ events }: { events: IEvent[] }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const { isDesktop } = useMatchMedia();

  const [sortMobileValue, setSortMobileValue] = useState(all); // мобильная сортировка
  const [sortValue, setSortValue] = useState('upcoming'); // десктопная сортировка
  const options = useMemo(
    () => ['upcoming', 'descendingFavEvents', 'ascendingFavEvents'].map(
      (el) => ({ value: el, label: t(`adv:filters.sortingModal.${el}`) }),
    ),
    [t],
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [sortMobileValue, sortValue]);

  const eventsCount = getDeclension({
    count: events.length,
    t,
    singular: 'favourites:favouriteEvents.one',
    few: 'favourites:favouriteEvents.few',
    many: 'favourites:favouriteEvents.many',
  });

  const existingTypes = useMemo(() => {
    const eventTypesSet = events.reduce((acc, ad) => {
      acc.add(ad.event_type);
      return acc;
    }, new Set<string>());

    const types = [all, ...Array.from(eventTypesSet)];
    const filteredTypes = types.filter((type) => type !== null && (EVENTS_TYPES.includes(type) || type === all));
    return [...filteredTypes, noCategory];
  }, [events, EVENTS_TYPES]); // для тегов сортировки в мобильном разрешении

  const handleMobileChange = (event: React.ChangeEvent<HTMLInputElement>) => setSortMobileValue(event.target.value);

  const quantityEventsByType = useCallback((eventType: string) => {
    if (eventType === all) return events.length;
    if (eventType === noCategory) {
      return events.length - events
        .filter((event) => EVENTS_TYPES.includes(event.event_type) && event !== null).length;
    }

    return events.filter((event) => event.event_type === eventType).length;
  }, [events, EVENTS_TYPES]);

  const filteringEvents = useMemo(() => {
    if (isDesktop) {
      return eventsSortingHelper(events, sortValue);
    }
    if (sortMobileValue === noCategory) {
      return events.filter((event) => !EVENTS_TYPES.includes(event.event_type) || event === null);
    }
    return events.filter((event) => sortMobileValue === all || event.event_type === sortMobileValue);
  }, [events, isDesktop, sortMobileValue, sortValue, EVENTS_TYPES]);

  return (
    <div className={styles.favourite_ads}>
      {!events.length ? (
        <h3 className={styles.title_no_ads}>{t('favourites:favouriteEvents.none')}</h3>
      ) : (
        <div className={styles.favourite_ads_wrapper}>
          {isDesktop ? (
            <div className={styles.favourite_ads_header}>
              <h3>{eventsCount}</h3>
              <Sorting
                value={sortValue}
                setValue={setSortValue}
                options={options}
                rightPosition
              />
            </div>
          ) : (
            <div className={styles.favourite_ads_header}>
              {existingTypes.map((eventType) => (
                <label
                  className={clsx(styles.label, {
                    [styles.label_active]: eventType === sortMobileValue,
                  })}
                  key={eventType}
                >
                  <input
                    type="checkbox"
                    value={eventType}
                    onChange={handleMobileChange}
                    checked={sortMobileValue === eventType}
                  />
                  {`${t(`events:category.${eventType}`)} (${quantityEventsByType(eventType)})`}
                </label>
              ))}
            </div>
          )}
          <div className={styles.ads}>
            <Pagination
              itemsPerPage={10}
              currentPage={currentPage}
              fullList={!isDesktop || events.length < 10}
              setCurrentPage={(page) => setCurrentPage(page)}
              totalPages={Math.ceil(filteringEvents.length / 10)}
              className={styles.favourite_pagination_wrapper}
            >
              {filteringEvents.map((event) => (
                <EventCard
                  data={event}
                  extendedCard={isDesktop}
                  key={event.id}
                />
              ))}
            </Pagination>
          </div>
        </div>
      )}
    </div>
  );
};
