import React, {
  ReactNode, useCallback, useEffect, useState,
} from 'react';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue,
} from 'react-hook-form';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { customComponents } from './custom-components';
import styles from './select.module.scss';

interface Props<T extends FieldValues> {
  setValue: UseFormSetValue<T>;
  control: Control<T, string[]>;
  name: Path<T>;
  placeholder?: string;
  closeMenuOnSelect?: boolean;
  options: SelectOption[];
  requiredFiled?: boolean;
  isSearchable?: boolean;
  autoSelectFirst?: boolean;
  isClearable?: boolean;
  defaultValue?: SelectOption;
  isDisabled?: boolean;
  children?: ReactNode;
  selectSize?: 'xs' | 's';
  className?: string;
  minShowItems?: number;
  onSideEffect?: () => void;
}

interface SelectOption {
  value: string | number | undefined;
  label: string | number | null | ReactNode;
}

export const UniversalSelect = <T extends FieldValues>(props: Props<T>) => {
  const {
    control,
    setValue,
    name,
    placeholder,
    closeMenuOnSelect = true,
    options,
    isSearchable = false,
    isClearable = true,
    autoSelectFirst = false,
    defaultValue,
    isDisabled,
    requiredFiled,
    children,
    selectSize,
    className,
    minShowItems = 5,
    onSideEffect,
  } = props;
  const animated = makeAnimated();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation('');

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue.value as PathValue<T, Path<T>>);
    }
  }, []);

  const handleChange = (
    selectedOption: SelectOption | null,
    fieldOnChange: (value: PathValue<T, Path<T>> | null) => void,
  ) => {
    let value: PathValue<T, Path<T>> | null = null; // react-select требует null для корректной очистки

    if (selectedOption && selectedOption !== defaultValue) {
      value = selectedOption.value as PathValue<T, Path<T>>;
    } else if (autoSelectFirst) {
      const firstOption = options[0];
      if (defaultValue) {
        value = defaultValue.value as PathValue<T, Path<T>>;
      } else if (firstOption) {
        value = firstOption.value as PathValue<T, Path<T>>;
      }
    }

    fieldOnChange(value);

    /*
    * Если isClearable === true, то при очистке значения вызывается handleChange(null) и выполнится
    * onSideEffect (если передан, конечно).
    * Например, если при сбросе города нужно сбросить район. Но обязательно нужно передать key, чтобы select
    * визуально обновился
    * */
    onSideEffect?.();
  };

  const selectStyle = clsx(
    styles.select,
    selectSize && styles[`size_${selectSize}`],
    isDropdownOpen && styles.open,
    className,
  );

  const nothingFoundText = useCallback(
    () => {
      const text = t('notFound:secondText');
      const firstLetter = text.charAt(0).toUpperCase();
      return `${firstLetter}${text.slice(1)}`;
    },
    [t],
  );

  return (
    <>
      {options && (
        <Controller
          name={name}
          control={control}
          rules={{
            required: {
              value: requiredFiled || false,
              message: '',
            },
          }}
          render={({ field }) => {
            const currentValue = options.find((option) => option.value === field.value) || null;
            /*
            * В сообщениях передан проп autoSelectFirst и задано defaultValue. В таком случае крестик очистки
            * не должен отображаться. Использовать useState для не получится, тк тогда начальное состояние будет false
            * и при повторном монтировании компонента крестик не будет отображаться, даже если значение выбрано.
            * */
            const isValueSelected = currentValue && (
              (currentValue.value !== defaultValue?.value)
              || (autoSelectFirst && currentValue.value !== options[0]?.value)
            );

            return (
              <Select
                {...field}
                className={selectStyle}
                id={name}
                components={{
                  ...animated,
                  Control: customComponents.Control,
                  Option: customComponents.Option,
                  ClearIndicator: isValueSelected ? customComponents.ClearIndicator : undefined,
                  IndicatorSeparator: customComponents.IndicatorSeparator,
                  DropdownIndicator: !isValueSelected ? (dropdownProps) => (
                    <customComponents.DropdownIndicator
                      {...dropdownProps}
                      isDropdownOpen={isDropdownOpen}
                    />
                  ) : undefined,
                  Menu: customComponents.Menu,
                  // передаем свои minShowItems для отображения минимального количества элементов, если текст в элементе слишком длинный
                  MenuList: (menuProps) => (
                    <customComponents.MenuList {...menuProps} minShowItems={minShowItems} />
                  ),
                }}
                placeholder={children || placeholder} // можно прокидывать или placeholder, или компонент с SVG/ReactNode
                closeMenuOnSelect={closeMenuOnSelect}
                isClearable={isClearable}
                options={options}
                defaultValue={defaultValue}
                isDisabled={isDisabled}
                isSearchable={isSearchable}
                onChange={(selectedOption) => handleChange(
                  selectedOption as SelectOption | null,
                  field.onChange,
                )}
                value={currentValue}
                onMenuOpen={() => setIsDropdownOpen(true)}
                onMenuClose={() => setIsDropdownOpen(false)}
                noOptionsMessage={nothingFoundText}
              />
            );
          }}
        />
      )}
    </>
  );
};
