import { QueryClient } from '@tanstack/react-query';

/**
 * Инвалидирует запросы в кэше, ключи которых содержат хотя бы один из указанных ключей. Инвалидация не сработает, если
 * у запроса enabled: false.
 * В listQueryFactory меняются ключи и добавляются query параметры,
 * поэтому ключи имеют примерно такой вид [undefined, undefined, 10, 1, 'my-active-events'].
 * Нужно найти все кешированные запросы, которые включают в себя 'my-active-events'
 *
 * @param queryClient - экземпляр QueryClient из @tanstack/react-query
 * @param keys - массив строк, содержащих ключи для инвалидирования запросов
 * @param refetchKeys - (необязательный) массив строк, содержащих ключи для рефетча запросов
 * @returns Promise<void>
 */

export const updateQueriesByKeys = async (
  queryClient: QueryClient,
  keys: string[],
  refetchKeys?: string[], // второй массив для рефетча
) => {
  const queries = queryClient.getQueriesData({});

  const getMatchingQueries = (targetKeys: string[]) => queries
    .map(([key]) => key)
    .filter((key) => Array.isArray(key) && targetKeys.some((targetKey) => key.includes(targetKey)));

  // Инвалидация запросов
  const invalidatePromises = getMatchingQueries(keys).map((key) => queryClient.invalidateQueries({ queryKey: key }));

  // Рефетч запросов, если передан массив
  const refetchPromises = refetchKeys
    ? getMatchingQueries(refetchKeys).map((key) => queryClient.refetchQueries({ queryKey: key }))
    : [];

  await Promise.all([...invalidatePromises, ...refetchPromises]);
};
