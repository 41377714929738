import React from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { SvgIcon } from '@/modules/shared/components';
import { profileBasePath } from '@/modules/shared/const';
import styles from './social-media-item-edit.module.scss';

interface SocialMediaItemEditProps {
  platform: string;
  isHasDate?: boolean;
  userName?: string;
  onClose?: () => void;
}

export const SocialMediaItemEdit = ({
  platform,
  isHasDate,
  userName,
  onClose,
}: SocialMediaItemEditProps) => {
  const { t } = useTranslation('');
  const isCurrentPageAccount = window.location.href.includes(`/${profileBasePath}/account`);

  const content = (
    <>
      <SvgIcon
        name={isHasDate ? platform.toLowerCase() : 'plus'}
        style={{ fontSize: '24px' }}
      />
      {isHasDate
        ? userName
        : t(`common:shareProfile.socialMedia.add.${platform}`)}
    </>
  );

  const className = clsx(styles.item, {
    [styles.item_view]: isHasDate,
    [styles.item_add]: !isHasDate,
  });

  if (isCurrentPageAccount) {
    return (
      <button type="button" className={className} onClick={onClose}>
        {content}
      </button>
    );
  }

  return (
    <Link href={`/${profileBasePath}/account`} className={className}>
      {content}
    </Link>
  );
};
