import React, {
  useEffect, useRef, useState, useMemo,
} from 'react';
import clsx from 'clsx';
import Cookie from 'js-cookie';
import useTranslation from 'next-translate/useTranslation';

import { User, UserTypeEnum } from '@/modules/users';
import { Button } from '@/modules/shared/components';
import { UserCardType } from '@/modules/shared/types';
import { useMatchMedia } from '@/modules/shared/hooks';
import { profileBasePath } from '@/modules/shared/const';
import { useAuthContext, useOpenAuthModal } from '@/modules/auth';
import { Company } from '../../../company/types';

import styles from './user-contacts.module.scss';

interface Props {
  adId?: number;
  userData: User;
  companyData?: Company;
  card: UserCardType;
  isDisabled: boolean;
  handleProtectedAction: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const UserContacts = (props: Props) => {
  const {
    isDisabled, userData, adId, card, companyData, handleProtectedAction,
  } = props;
  const { t } = useTranslation('');
  const openAuthModal = useOpenAuthModal();
  const { isAuthenticated } = useAuthContext();
  const { isMobile, isDesktop } = useMatchMedia();

  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const phoneButtonRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null);

  const companyLinks = companyData?.social_networks;
  const isHiddenPhoneNumber = userData?.phone_number_is_hidden;
  const isMessageToMyself = Cookie.get('user_id') === String(userData.id);
  const isCompany = userData.user_type === UserTypeEnum.Company && userData.company_id;
  const hasPhoneNumber = userData.phone_number || companyData?.contact_info?.phone_number;
  const disabled = !isDisabled || !isAuthenticated;

  useEffect(() => {
    if (phoneButtonRef.current) {
      if (!isDesktop && phoneButtonRef.current instanceof HTMLAnchorElement) {
        phoneButtonRef.current.setAttribute(
          'href',
          `tel:${userData.phone_number}`,
        );
      } else {
        phoneButtonRef.current.removeAttribute('href');
      } // если не десктоп и не должно быть ссылкой, удаляем атрибут href
    } // на телефонах и планшетах должна быть ссылка, а на десктопах кнопка
  }, [isDesktop, disabled, userData]);

  const togglePhoneNumber = () => {
    if (isAuthenticated) setShowPhoneNumber((prev) => !prev);
    else openAuthModal('login');
  };

  const phoneButtonText = useMemo(() => {
    if (isDesktop) return showPhoneNumber && !isHiddenPhoneNumber ? userData.phone_number : t('adv:contacts.viewPhone');
    return t('adv:contacts.call');
  }, [isDesktop, showPhoneNumber, isHiddenPhoneNumber, userData.phone_number, t]);

  return (
    <div
      className={clsx(styles.user_contacts, {
        [styles.user_ad_contacts]: card === 'adUser',
      })}
    >
      <Button
        fullWidth
        as="link"
        buttonSize="m"
        appearance="primary"
        className={styles.chat}
        buttonColor={isMessageToMyself ? 'grey' : 'blue'}
        href={`/${profileBasePath}/chat/user/${userData.id}${adId ? `?ad=${adId}` : ''}`}
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => handleProtectedAction(event)}
      >
        {isDesktop ? t('adv:contacts.writeMessage') : t('adv:contacts.write')}
      </Button>
      {hasPhoneNumber && (
        <Button
          fullWidth
          buttonSize="m"
          appearance="primary"
          ref={phoneButtonRef}
          className={styles.phone}
          onClick={togglePhoneNumber}
          as={isDesktop ? 'button' : 'link'}
          href={
            !isDesktop
            && isAuthenticated
            && !isHiddenPhoneNumber
            && !isMessageToMyself
              ? `tel:${userData.phone_number}`
              : undefined
          }
          buttonColor={
            isMessageToMyself || isHiddenPhoneNumber ? 'grey' : 'green'
          }
        >
          {phoneButtonText}
        </Button>
      )}
      {!isMobile && !!isCompany && companyLinks?.telegram && (
        <Button
          as="link"
          fullWidth
          buttonSize="m"
          target="_blank"
          buttonColor="grey"
          appearance="primary"
          className={styles.telegram}
          onClick={handleProtectedAction}
          href={`https://t.me/${companyLinks?.telegram}`}
        >
          {t('adv:contacts.telegram')}
        </Button>
      )}
    </div>
  );
};
