import React, { useCallback, useEffect, useState } from 'react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { useCityPrefix } from '@/context/CityPrefixContext';
import { SvgIcon } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { useRouter } from 'next/router';
import { stopEvent, useLogoClick } from '@/modules/shared/helpers';
import { createPortal } from 'react-dom';
import { useCategories } from '@/modules/categories';
import { useGlobalData } from '@/context/GlobalContext';
import { SearchBar } from '../search-bar';
import { CategoryButton, LinearCategories } from '../categories';
import { LocationClarification } from '../index-page';
import { CategoriesModal } from '../categories/categories-modal';
import { HeaderProps } from './types';
import {
  NOT_DISPLAY_PATHS, AD_SEARCH_KEY, ADD_EVENT_PATH, LINEAR_CATEGORIES_PATH,
} from './consts';
import s from './secondary-header.module.scss';

export const SecondaryHeader = ({
  showCityModal, setShowCityModal,
}: HeaderProps) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [modalRoot, setModalRoot] = useState<Element | null>(null);
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const router = useRouter();
  const onLogoClick = useLogoClick(router);
  const { categories } = useCategories();
  const { cityPrefix } = useCityPrefix();
  const { t } = useTranslation('common');
  const { isShowCategoriesModal, setIsShowCategoriesModal } = useGlobalData();

  const handleBack = () => {
    setIsInputFocused(false);

    if (window.history.length > 1) {
      router.back();
    } else {
      router.push('/');
    }
  };

  const onSearchAd = (value: string) => {
    const trimmedValue = value.trim();

    if (trimmedValue === '') {
      if (router.asPath.includes(`/${cityPrefix}/catalog/all`)) return;
      const { search, ...restQuery } = router.query;
      router.push({
        pathname: router.pathname,
        query: restQuery,
      });
      return;
    }

    if (!router.pathname.includes('/catalog/')) {
      router.push(`/${cityPrefix}/catalog/all?search=${trimmedValue}`);
    } else {
      const query = { ...router.query, search: trimmedValue };
      router.push({
        pathname: router.pathname,
        query,
      });
    }
  };

  const handleEscape = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsShowCategoriesModal(false);
    }
  }, []);

  useEffect(() => {
    if (isShowCategoriesModal) {
      if (window.scrollY > 100) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }, [isShowCategoriesModal]);

  useEffect(() => {
    const mainSda = document.querySelector('.main_sda');
    if (mainSda) {
      setModalRoot(mainSda);
    }
  }, []);

  useEffect(() => {
    if (isShowCategoriesModal) {
      document.addEventListener('keydown', handleEscape);
    } else {
      document.removeEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isShowCategoriesModal, handleEscape]);

  const isAddEventPath = router.pathname.includes(ADD_EVENT_PATH);

  return (
    <div className={clsx(s.secondary_header_wrapper, {
      [s.add_event_header]: isAddEventPath,
    })}
    >
      <div className={`${s.secondary_header}`}>
        {!isTablet && !isMobile && (
        <div className={s.logo} data-testid="header-logo">
          <Link href={`/${router.locale || 'en'}`} onClick={onLogoClick}>
            <SvgIcon name="logo-main-large" />
          </Link>
        </div>
        )}
        {!NOT_DISPLAY_PATHS.some((path) => router.pathname.includes(path)) && (
        <>
          {isDesktop && <CategoryButton />}
          {isMobile && !isTablet && !isInputFocused ? <CategoryButton /> : null }
          {!isMobile && isTablet && router.pathname === '/' && <CategoryButton /> }

          {!isMobile && isTablet && router.pathname !== '/' && (
            <button onClick={handleBack} type="button" onMouseDown={stopEvent} className={s.back_btn}>
              <SvgIcon name="arrow-back" style={{ fontSize: '24px' }} />
            </button>
          )}
          <div className={s.search_bar}>
            <SearchBar
              isInputFocused={isInputFocused}
              setIsInputFocused={setIsInputFocused}
              keyHistory={AD_SEARCH_KEY}
              onSearchFilter={onSearchAd}
              text={{ placeholder: t('searchBlock.searchAds') }}
              shouldClearOnRouteChange
              showSuggestions
            />
          </div>
          {!isTablet && !isMobile && (
            <LocationClarification
              isShowModal={showCityModal}
              onChange={() => setShowCityModal(!showCityModal)}
            />
          )}
        </>
        )}
        {LINEAR_CATEGORIES_PATH.some((path) => router.pathname.includes(path))
        && (<LinearCategories data={categories} />)}
        {isShowCategoriesModal && modalRoot && (
          createPortal(
            <CategoriesModal modalRootBlock={modalRoot} />,
            modalRoot,
          )
        )}
      </div>
    </div>
  );
};
