import Rollbar from 'rollbar';
import { isServerSide } from '@/modules/shared/helpers';
import getConfig from 'next/config';

const { publicRuntimeConfig: { environment } } = getConfig();

const baseConfig = {
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment,
};

export const rollbar = new Rollbar({
  ...baseConfig,
  accessToken: isServerSide() ? process.env.ROLLBAR_SERVER_TOKEN : process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
  enabled: Boolean(isServerSide() ? process.env.ROLLBAR_SERVER_TOKEN : process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN),
});
