import React from 'react';
import styles from './user-avatar.module.scss';

interface UserAvatarProps {
  srcAvatar: string | null;
  userName: string;
  diameter?: '16px' | '24px' | '32px' | '36px' | '44px' | '48px' | '64px' | '66px' | '100px' | '140px' | '152px';
  style?: React.CSSProperties;
}

const fontSizeMap: Record<string, string> = {
  '152px': '48px',
  '140px': '96px',
  '100px': '48px',
  '66px': '40px',
  '64px': '40px',
  '48px': '32px',
  '44px': '24px',
  '36px': '24px',
  '32px': '20px',
  '24px': '16px',
  '16px': '11px',
};

export const UserAvatar = ({
  srcAvatar, userName, diameter = '66px', style,
} : UserAvatarProps) => {
  const fontSize = fontSizeMap[diameter];
  const userNameAvatar = userName?.slice(0, 1)?.toUpperCase();

  const border = parseInt(diameter, 10) > 48 ? '1px solid #CDCDCF' : '0.5px solid #CDCDCF';
  const background = srcAvatar ? 'transparent' : '';

  return (
    <div
      className={styles.avatar}
      style={{
        width: diameter,
        height: diameter,
        border,
        background,
        ...style,
      }}
    >
      {srcAvatar
        ? (
          <span className={styles.avatar_img}>
            <img src={srcAvatar} alt={userNameAvatar} />
          </span>
        )
        : <span style={{ fontSize }}>{userNameAvatar}</span>}
    </div>
  );
};
